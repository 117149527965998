import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'

import { Grid, makeStyles } from '@material-ui/core'
import { SelectInput } from '@ether-healthcare/inputs'
import { Button } from '@components/buttons'
import {
  SharingPermissionEnum,
  SharingExpirationEnum,
  DoctorDto,
  AccountRoleEnum,
} from '@services/api'
import { share } from '@state/thunks/studyThunk'
import { DoctorAutocompleteByNameInput } from '@components/inputs'
import { useIntl } from 'react-intl'
import { useSelector } from '@state/store'
import { shareStudySchema } from '@utils/schemas'

type Values = {
  doctor: DoctorDto
  accountId: number
  expiration: SharingExpirationEnum
  permission: SharingPermissionEnum
}

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
}))

type AddSharingFormProps = {
  medicalOrderId: number
}

export function AddSharingForm({ medicalOrderId }: AddSharingFormProps) {
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()

  const { loading, account } = useSelector(({ loading, auth }) => ({
    loading: loading['study/share'],
    account: auth.account,
  }))

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(shareStudySchema),
    defaultValues: {
      expiration: SharingExpirationEnum.NO_EXPIRATION,
      permission: SharingPermissionEnum.VIEW,
    },
  })

  const onSubmit = (values: Values) => {
    dispatch(
      share({
        medicalOrderId,
        expiration: values.expiration,
        permission: values.permission,
        accountId: values.doctor.accountId,
      }),
    )
  }

  const studySharingPermissions = Object.keys(SharingPermissionEnum)
    .filter((permission) => {
      if (
        account?.role === AccountRoleEnum.doctor &&
        permission == SharingPermissionEnum.OWNER
      )
        return false
      else {
        return true
      }
    })
    .map((key) => ({
      value: SharingPermissionEnum[key],
      label: intl.formatMessage({
        id: 'enums.sharingPermission.' + SharingPermissionEnum[key],
      }),
    }))
  const studySharingExpirations = Object.keys(SharingExpirationEnum).map(
    (key) => ({
      value: SharingExpirationEnum[key],
      label: intl.formatMessage({
        id: 'enums.sharingExpiration.' + SharingExpirationEnum[key],
      }),
    }),
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.gridItem}>
        <DoctorAutocompleteByNameInput
          control={control}
          errors={errors}
          name="doctor"
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          options={studySharingPermissions}
          control={control}
          name="permission"
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          options={studySharingExpirations}
          control={control}
          name="expiration"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          text="submit"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          color="primary"
          format
        />
      </Grid>
    </Grid>
  )
}
