// import { useRef } from 'react'
import { Button } from '@components/buttons'
import { DialogTitle } from '@components/titles'
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import { Check } from '@material-ui/icons'
// import SignatureCanvas from 'react-signature-canvas'
import { Text } from '@components/texts'
type ConsentDialogProps = {
  open: boolean
  onSign(signature: string): void
  setOpen: (open: boolean) => void
}

export function ConsentDialog({ open, setOpen }: ConsentDialogProps) {
  // const refSign = useRef<SignatureCanvas>(null)

  // const handleSign = () => {
  //   if (refSign.current) {
  //     const sign = refSign.current.toDataURL('image/png')
  //     if (sign) onSign(sign)
  //   }
  // }
  const handleClose = () => {
    // if (refSign.current) {
    //   refSign.current.clear()
    // }
    setOpen(false)
  }
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle onClose={() => {}} title="consent" format/>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ overflowY: 'scroll', height: 400 }}>
            <Text text="dataProtectionConsent" format />
            {/* <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 800, height: 300 }}
              ref={refSign}
            /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          text="consent-validation"
          startIcon={<Check />}
          onClick={handleClose}
          format
          color="primary"
        />
      </DialogActions>
    </Dialog>
  )
}

// export default memo(SignatureModal);
