import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
// import { useDispatch } from 'react-redux'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { Button } from '@components/buttons'
import { AutocompletFullNameInput } from '@components/inputs'

import { AddAttendingDoctorDialog } from '@components/dialogs/AddAttendingDoctorDialog'
import { PatientDto } from '@services/api'
import { Text } from '../texts'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type MedicalInfoFormProps = {
  profile: PatientDto
}

type Values = {
  id: number
  shareNew: boolean
  shareOld: boolean
}

export function MedicalInfoForm({ profile }: MedicalInfoFormProps) {
  console.log(profile.referringDoctor)
  const classes = useStyles()
  // const dispatch = useDispatch()
  const [doctor, setDoctor] = useState<any>({
    fullName: profile.referringDoctor
      ? profile.referringDoctor.lastName +
        ' ' +
        profile.referringDoctor.firstName
      : '',
    id: profile.referringDoctor?.id,
  })
  const [open, setOpen] = useState(false)
  const { register, handleSubmit, control, setValue } = useForm<any>({
    defaultValues: {
      shareNew: true,
      shareOld: true,
    },
  })
  const onSubmit = (values: Values) => {
    console.log(values)
    const val = {
      rppsNumber: values.id,
      shareOld: values.shareOld,
      shareNew: values.shareNew,
    }
    console.log(val)
    // dispatch(
    //   updateReferringDoctor({
    //     id: profile.id as number,
    //     body: {
    //       rppsNumber: values.id,
    //       shareOld: values.shareOld,
    //       shareNew: values.shareNew,
    //     },
    //   }),
    // )
    setOpen(false)
  }

  useEffect(() => {
    if (doctor) {
      setValue('id', doctor?.id)
    }
  }, [doctor, register, setValue])

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-heartbeat" />
            </Grid>
            <Grid item>
              <Text text="medicalInfo" format />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AutocompletFullNameInput
              doctor={profile.referringDoctor}
              onSelect={setDoctor}
            />
            <Button
              text="Your doctor is not in the list ?"
              onClick={() => console.log('dialog add doctor')}
              variant="text"
              textColor="primary"
              //TODO: doctor not in the list dialog
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              text="submit"
              onClick={handleSubmit(onSubmit)}
              format
              color="primary"
            />
          </Grid>
        </Grid>
      </CardActions>

      <AddAttendingDoctorDialog
        control={control}
        open={open}
        handleSubmit={handleSubmit(onSubmit)}
        onClose={() => setOpen(false)}
      />
    </Card>
  )
}
