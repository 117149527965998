import { TableCell, TextField, withStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ModalityEnum } from '@services/api'
import { useIntl } from 'react-intl'

type ExamTypeFilterCellBaseProps = {
  onFilter: (filter: any) => void
}

const ExamTypeFilterCellBase = ({ onFilter }: ExamTypeFilterCellBaseProps) => {
  const intl = useIntl()
  const options = Object.keys(ModalityEnum).map((key) => ({
    value: ModalityEnum[key],
    label: intl.formatMessage({ id: 'enums.modality.' + ModalityEnum[key] }),
  }))
  return (
    <TableCell>
      <Autocomplete
        fullWidth
        options={options}
        getOptionLabel={(option) =>
          intl.formatMessage({ id: 'enums.modality.' + option.value })
        }
        onChange={(event: any, newValue: { value: string } | null) => {
          onFilter(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // variant="outlined"
            size="small"
            placeholder="Filter..."
          />
        )}
      />
    </TableCell>
  )
}
const stylesStudyStatusFilter = () => ({})

export default withStyles(stylesStudyStatusFilter, {
  name: 'ExamTypeFilterCellBase',
})(ExamTypeFilterCellBase)
