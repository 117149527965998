import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './authReducer'
import alertReducer from './alertReducer'
import loadingReducer from './loadingReducer'
import studyReducer from './studyReducer'
import notificationReducer from './notificationReducer'

const appReducer = combineReducers({
  auth: authReducer,
  study: studyReducer,
  alert: alertReducer,
  loading: loadingReducer,
  notification: notificationReducer,
})
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default rootReducer
