import { useState } from 'react'

import { useForm } from 'react-hook-form'
// import { useDispatch } from 'react-redux'

import {
  AppBar,
  Card,
  CardActions,
  Grid,
  Icon,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core'
import { Button } from '@components/buttons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

// import { setUserShare } from '@state/reducers/testReducers'
import { Text } from '../texts'
import { TabPanel, a11yProps } from '@components/tabs'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
  grid: {
    width: '100%',
    padding: '10px',
    paddingTop: '0px',
  },
}))

type UserSharingFormProps = {
  userShare: {
    orgPerm: string
    orgExpire: number
    proPerm: string
    proExpire: number
  }
  doctors: {
    id: number
    name: string
    organizations: {
      id: number
    }[]
    city: string
    state: string
    favorite: boolean
    recipients: string[]
  }[]
  organizations: {
    id: number
    name: string
    city: string
    state: string
    favorite: boolean
    recipients: string[]
  }[]
}

type Value = {
  userShare: {
    orgPerm: string
    orgExpire: number
    proPerm: string
    proExpire: number
  }
  doctors: {
    id: number
    name: string
    organizations: {
      id: number
    }[]
    city: string
    state: string
    favorite: boolean
    recipients: string[]
  }[]
  organizations: {
    id: number
    name: string
    city: string
    state: string
    favorite: boolean
    recipients: string[]
  }[]
}
export default function UserSharingForm({
  userShare,
  doctors,
  organizations,
}: UserSharingFormProps) {
  const { orgPerm, orgExpire, proPerm, proExpire } = userShare
  const [value, setValue] = useState(0)
  const [tab, setTab] = useState(0)

  const classes = useStyles()
  // const dispatch = useDispatch()
  const { handleSubmit } = useForm<Value>({
    defaultValues: {
      userShare: {
        orgPerm: orgPerm,
        orgExpire: orgExpire,
        proPerm: proPerm,
        proExpire: proExpire,
      },
      doctors: doctors,
      organizations: organizations,
    },
  })
  const onSubmit = () => {
    // dispatch(setUserShare(values))
  }
  const handleValue = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Card className={classes.section}>
      <Grid>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Icon className="fa fa-share" />
          </Grid>
          <Grid item>
            <Text text="User Share" format />
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleValue}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <Tab label="Organisation" {...a11yProps(0)} />
              <Tab label="Professional" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid className={classes.grid}>
              <ToggleButtonGroup
                value={tab}
                exclusive
                onChange={handleTab}
                aria-label="text alignment"
              >
                <ToggleButton value="left" aria-label="left aligned">
                  <Text text="Directory Search" />
                </ToggleButton>
                <ToggleButton value="center" aria-label="centered">
                  <Text text="Favorite" />
                </ToggleButton>
                <ToggleButton value="right" aria-label="right aligned">
                  <Text text="Recent" />
                </ToggleButton>
                <ToggleButton value="justify" aria-label="justified" disabled>
                  <Text text="Invite" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
      <CardActions>
        <Button text="submit" onClick={handleSubmit(onSubmit)} />
      </CardActions>
    </Card>
  )
}
