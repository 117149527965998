import { MenuItem, Select, TableCell, withStyles } from '@material-ui/core'
import { SexEnum } from '@services/api'

import { FormattedMessage } from 'react-intl'

const SexFilterCellBase = ({ filter, onFilter }: any) => (
  <TableCell>
    <Select
      id="select"
      displayEmpty
      value={filter ? filter.value : ''}
      onChange={(e) =>
        onFilter(
          e.target.value ? { value: e.target.value, operation: 'equal' } : null,
        )
      }
      fullWidth
    >
      <MenuItem value="">
        <em>
          <FormattedMessage id="all" />
        </em>
      </MenuItem>
      {Object.keys(SexEnum)
        .map((key) => SexEnum[key])
        .map((item, index: number) => (
          <MenuItem key={index} value={item}>
            <em>{item && <FormattedMessage id={'enums.sex.' + item} />}</em>
          </MenuItem>
        ))}
    </Select>
  </TableCell>
)
const stylesSexFilter = () => ({})

export default withStyles(stylesSexFilter, {
  name: 'SexFilterCell',
})(SexFilterCellBase)
