import { Spinner } from '@components/loadings'
import {
  Grid,
  IconButton,
  Icon,
  CircularProgress,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { GedService, MedicalOrderDto } from '@services/api'
import { useState, useEffect, memo } from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  div: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: 250,
  },
  iconBtn: {
    padding: 0,
  },
  icon: {
    marginRight: '1.5rem',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
}))

type ReportTooltipProps = {
  study: MedicalOrderDto
}

type URLReport = {
  filename: string
  url: string
}

const ReportTooltip = ({ study }: ReportTooltipProps) => {
  const intl = useIntl()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [reports, setReports] = useState<URLReport[]>([])
  const [loading, setLoading] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  //   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  //     setAnchorEl(event.currentTarget)
  //   }
  //   const handlePopoverClose = () => {
  //     setAnchorEl(null)
  //   }
  const iconId = 'icon-' + study.id
  const popoverId = 'popover-' + study.id
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (study.reports.length && open) {
      setLoading(true)
      const fetchReports = async () => {
        const _reports: URLReport[] = await Promise.all(
          study.reports.map(async (report) => {
            const { filename, url } = await GedService.downloadFile(
              { id: report.id },
              { responseType: 'blob' },
            )
            return { filename, url }
          }),
        )
        setReports(_reports)
      }
      fetchReports()
      setLoading(false)
    }
    return () => {
      reports.forEach((report) => URL.revokeObjectURL(report.url))
      setReports([])
    }
  }, [open, study.reports])

  return (
    // <Tooltip title={intl.formatMessage({ id: 'report' })}>
    <span>
      <IconButton
        size="small"
        color={study.reports.length > 0 ? 'primary' : undefined}
        id={iconId}
        aria-label={intl.formatMessage({ id: 'report' })}
        aria-describedby={popoverId}
        className={classes.iconBtn}
        onClick={handleClick}
        disabled={study.reports.length === 0}
      >
        {!loading ? (
          <Icon
            id={popoverId}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            fontSize="small"
            className="fa fa-file-pdf"
            color={study.reports.length === 0 ? 'disabled' : 'primary'}
          />
        ) : (
          <CircularProgress size={20} />
        )}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <List>
              {!loading ? (
                reports.map((report, index) => (
                  <ListItem
                    key={index}
                    onClick={() => window.open(report.url)}
                    style={{ cursor: 'pointer' }}
                  >
                    <ListItemIcon>
                      <Icon className="fa fa-file" />
                    </ListItemIcon>
                    <ListItemText primary={report.filename} />
                  </ListItem>
                ))
              ) : (
                <Spinner />
              )}
            </List>
          </Grid>
        </Grid>
      </Popover>
    </span>
    // </Tooltip>
  )
}

export const ReportTooltipMemo = memo(ReportTooltip)
