import { useSelector } from '@state/store'

import { Container, Theme, makeStyles } from '@material-ui/core'

import HomePatient from './patient/HomePatient'
import HomeDoctor from './doctor/HomeDoctor'
import { AccountRoleEnum } from '@services/api'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // paddingLeft: isMobileOnly ? '' : theme.spacing(12),
  },
}))

export default function Home() {
  const classes = useStyles()
  const account = useSelector(({ auth }) => auth.account)

  return (
    <Container maxWidth="xl" className={classes.container}>
      {(account?.role === AccountRoleEnum.patient ||
        account?.role === AccountRoleEnum.guest) && <HomePatient />}
      {account?.role === AccountRoleEnum.doctor && <HomeDoctor />}
    </Container>
  )
}
