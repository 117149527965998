import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { useSnackbar } from 'notistack'
// import { removeSnackbar } from '@state/actions/alert'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import { removeSnackbar } from '@state/reducers/alertReducer'
import { useIntl } from 'react-intl'

let displayed: number[] = []

export default function Notifier() {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const notifications = useSelector(({ alert }) => alert.notifications)

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id]
  }
  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key)
          return
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return

        // display snackbar using notistack
        try {
          message = intl.formatMessage({ id: message })
        } catch (error) {
          console.error(error)
          message = 'Unknonw error ! We\'re are on it'
        }
        enqueueSnackbar(message, {
          key,
          action: (key: number) => (
            <IconButton aria-label="delete" onClick={() => closeSnackbar(key)}>
              <Delete />
            </IconButton>
          ),
          ...options,
          // onClose: (event, reason, myKey) => {
          //     if (options.onClose) {
          //         options.onClose(event, reason, myKey);
          //     }
          // },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey as number))
            removeDisplayed(myKey as number)
          },
        })

        // keep track of snackbars that we've displayed
        storeDisplayed(key)
      },
    )
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}
