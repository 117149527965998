import {
  CreateShareDto,
  CreateShareWithGuestDto,
  GedService,
  MedicalOrderService,
  UpdateMedicalOrderDto,
} from '@services/api'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const findAll = createAsyncThunk(
  'study/findAll',
  async ({ skip, limit }: { skip: number; limit: number }, { dispatch }) => {
    try {
      const studies: any = await MedicalOrderService.findAll({
        skip,
        limit,
      })
      return studies
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const findOne = createAsyncThunk(
  'study/findOne',
  async (id: number, { dispatch }) => {
    try {
      return await MedicalOrderService.findOne({ id })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const update = createAsyncThunk(
  'study/update',
  async (
    { id, body }: { id: number; body: UpdateMedicalOrderDto },
    { dispatch },
  ) => {
    try {
      return await MedicalOrderService.update({ id, body })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const share = createAsyncThunk(
  'study/share',
  async (body: CreateShareDto, { dispatch, rejectWithValue }) => {
    try {
      const share = await MedicalOrderService.share({ body })
      dispatch(
        enqueueSnackbar({ type: 'success', message: 'study/share/fulfilled' }),
      )
      return share
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue('network-error')
    }
  },
)

export const shareWithGuest = createAsyncThunk(
  'study/shareWithGuest',
  async (body: CreateShareWithGuestDto, { dispatch, rejectWithValue }) => {
    try {
      const share = await MedicalOrderService.shareWithGuest({ body })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'study/shareWithGuest/fulfilled',
        }),
      )
      return share
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue('network-error')
    }
  },
)

export const unshare = createAsyncThunk(
  'study/unshare',
  async (id: number, { dispatch }) => {
    try {
      await MedicalOrderService.unshare({ id })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'study/unshare/fulfilled',
        }),
      )
      return id
      // dispatch(loginFulfilled({ user, token }))
      // return fulfillWithValue('study/share/fulfilled')
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)

export const findAllShares = createAsyncThunk(
  'share/findAll',
  async (id: number, { dispatch }) => {
    try {
      return await MedicalOrderService.findSharesByMedicalOrderId({ id })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const findOnePrescription = createAsyncThunk(
  'prescription/findOne',
  async (id: number, { dispatch }) => {
    try {
      const { url, filename } = await GedService.downloadFile(
        { id },
        {
          responseType: 'blob',
        },
      )
      return { url, filename, id }
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const uploadPrescription = createAsyncThunk(
  'prescription/upload',
  async ({ id, files, type }: any, { dispatch }) => {
    try {
      return MedicalOrderService.uploadPrescription({
        id,
        file: files,
        type,
      })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)
export const deletePrescription = createAsyncThunk(
  'prescription/delete',
  async ({ id, prescriptionId }: any, { dispatch }) => {
    try {
      return MedicalOrderService.deletePrescription({
        id,
        prescriptionId,
      })
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const findOneReport = createAsyncThunk(
  'report/findOne',
  async (id: number, { dispatch }) => {
    try {
      const { filename, data } = await GedService.downloadFile(
        { id },
        {
          responseType: 'blob',
        },
      )
      return {
        filename,
        url: URL.createObjectURL(new Blob([data], { type: 'application/pdf' })),
      }
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      throw e
    }
  },
)

export const downloadImage = createAsyncThunk<any, any, any>(
  'study/download',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data, filename } = await MedicalOrderService.download(
        { id },
        {
          responseType: 'blob',
        },
      )
      return {
        url: URL.createObjectURL(new Blob([data], { type: 'application/zip' })),
        filename,
      }
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
    }
  },
)
