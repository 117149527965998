import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { ConfirmPhoneDialog } from '@components/dialogs'
import { PhoneInput } from '@ether-healthcare/inputs'
import { Button } from '@components/buttons'

import { Text } from '../texts'
import { updatePhone } from '@state/thunks/authThunk'
import { AccountDto } from '@services/api'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type UpdatePhoneFormProps = {
  account?: AccountDto
}

type Values = {
  phoneNumber: string
}
export function UpdatePhoneForm({ account }: UpdatePhoneFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { handleSubmit, control } = useForm<Values>({
    //TODO phone validation schema
    defaultValues: {
      phoneNumber: account?.phone,
    },
  })
  const [openPhoneDialog, setOpenPhoneDialog] = useState(false)

  const handleOpenPhoneDialog = () => {
    setOpenPhoneDialog(true)
  }
  const handleClosePhoneDialog = () => {
    setOpenPhoneDialog(false)
  }
  const onSubmit = ({ phoneNumber }: Values) => {
    dispatch(updatePhone({ phoneNumber }))
  }
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-phone" />
            </Grid>
            <Grid item>
              <Text text="phoneNumber" variant={'h6'} format />
            </Grid>
          </Grid>
        }
      />

      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <PhoneInput control={control} name="phoneNumber" />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              text="submit"
              onClick={handleSubmit(onSubmit)}
              format
              color="primary"
            />
          </Grid>

          <Grid item>
            <Button
              text="gotCode"
              color="primary"
              format
              onClick={handleOpenPhoneDialog}
            />
          </Grid>
        </Grid>
      </CardActions>
      <ConfirmPhoneDialog
        open={openPhoneDialog}
        onClose={handleClosePhoneDialog}
      />
    </Card>
  )
}
