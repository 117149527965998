import { cloneElement, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  CustomPaging,
  IntegratedSorting,
  PagingState,
  PagingStateProps,
  Row,
  RowDetailState,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Toolbar,
  VirtualTable as Table,
  TableColumnReordering,
  TableFilterRow,
  TableHeaderRow,
  TableFixedColumns,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui'
import {
  Button,
  Hidden,
  IconButton,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { ViewColumn } from '@material-ui/icons'
import { FilterCell, FilterIcon } from '@components/filters'
import { GridSpinner } from '@components/loadings'

import { DetailEditCell } from '../plugins/DetailEditCell'
import { ActionColumns, HeaderToolbar, ToolbarOptions } from '../plugins'

import { AnyDataTypeProvider } from './AnyDataTypeProvider'
import { AccountRoleEnum } from '@services/api'

const useStyles = makeStyles({
  btn: {
    marginRight: 20,
  },
  selectedRowCursor: {
    cursor: 'pointer',
  },
  defaultCursor: {
    cursor: 'default',
  },
  paper: {
    overflowX: 'auto',
    position: 'relative',
    borderRadius: 25,
  },
})

const toggleButtonComponent = (props: any) => (
  <>
    <Hidden smDown>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onToggle}
        ref={props.ref}
        endIcon={<ViewColumn />}
        style={{
          marginRight: 20,
        }}
      >
        <FormattedMessage id="columns" />
      </Button>
    </Hidden>
    <Hidden mdUp>
      <IconButton onClick={props.onToggle} ref={props.ref}>
        <ViewColumn />
      </IconButton>
    </Hidden>
  </>
)

const DetailCell = ({
  children,
  // changeRow,
  // editingRowIds,
  // addedRows,
  // processValueChange,
  // applyChanges,
  //@ts-ignore
  onClose,
  ...restProps
}: TableRowDetail.CellProps) => {
  console.log('restProps', restProps)
  const { row } = restProps
  return (
    <TableRowDetail.Cell {...restProps}>
      {/*@ts-ignore*/}
      {cloneElement(children, {
        row,
        onClose,
      })}
    </TableRowDetail.Cell>
  )
}

type IntegrateTableProps = {
  role: AccountRoleEnum
  rows: Row[]
  columns: Column[]
  striped?: boolean
  loading: boolean
  stringColumns?: string[]
  booleanColumns?: string[]
  columnExtensions?: Table.ColumnExtension[]

  columnOrder?: string[]
  setColumnOrder?: (columnOrder: string[]) => void
  columnWidths?: TableColumnWidthInfo[]
  setColumnWidths?: (columnWidths: TableColumnWidthInfo[]) => void
  hiddenColumns?: string[]
  setHiddenColumns?: (row: string[]) => void

  onRowSelected?: (row: Row) => void
  actionColumns?: ActionColumn[]
  columnGroup?: string[]
  group?: string[]
  setColumnGroup?: (columnGroup: string[]) => void
  headerIcon?: string
  headerTitle?: string
  refresh?: () => void
  contentComponent?: any //React.ComponentType<TableRowDetail.ContentProps>
  dialogTitle?: string
  formDialog?: any
  rightColumns?: string[]
  pagingState?: PagingStateProps & { totalCount: number }
}

/**
 *
 * Table with integrated module
 *
 * @param striped Display strip 1 lines of 2
 *
 */
export function IntegrateTable({
  rows,
  columns,
  loading,
  stringColumns = [],
  booleanColumns = [],
  actionColumns = [],
  columnOrder,
  setColumnOrder,
  columnExtensions,
  hiddenColumns,
  setHiddenColumns,
  setColumnWidths,
  columnWidths,
  onRowSelected,
  group = [],
  columnGroup = [],
  setColumnGroup,
  dialogTitle,
  formDialog,
  headerIcon,
  headerTitle,
  refresh,
  contentComponent,
  role,
  rightColumns,
  pagingState,
}: IntegrateTableProps) {
  const intl = useIntl()
  const classes = useStyles()

  const TableRow = ({ row, ...restProps }: any) => (
    <Table.Row
      {...restProps}
      onClick={onRowSelected ? () => onRowSelected(row) : null}
      className={
        onRowSelected ? classes.selectedRowCursor : classes.defaultCursor
      }
    />
  )
  const stringFilterOperations = ['startsWith', 'contains', 'endsWith']
  const [pageSizes] = useState([2, 5, 10, 15])

  const commitChanges = () => {}

  const trasnlatedColumns = (columns: Column[]) =>
    columns.map((column) => ({
      ...column,
      title: intl.formatMessage({ id: column.title }),
    }))

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid
        rows={rows}
        columns={trasnlatedColumns(columns)}
        getRowId={(row) => row.id}
      >
        <SortingState defaultSorting={[]} />
        <PagingState {...pagingState} />
        <FilteringState
          defaultFilters={[]}
          columnExtensions={[
            { columnName: 'actions', filteringEnabled: false },
          ]}
        />
        <EditingState onCommitChanges={commitChanges} />
        <RowDetailState />

        <DataTypeProvider
          for={stringColumns}
          availableFilterOperations={stringFilterOperations}
        />
        <AnyDataTypeProvider columns={booleanColumns} type="boolean" />
        <AnyDataTypeProvider type="time" />
        <AnyDataTypeProvider type="date" />
        <AnyDataTypeProvider type="medicalOrderStatus" />
        <AnyDataTypeProvider type="siteStatus" />
        <AnyDataTypeProvider type="sex" />
        <AnyDataTypeProvider type="skill" />

        <DragDropProvider />
        <IntegratedSorting />
        <CustomPaging totalCount={pagingState?.totalCount} />
        <IntegratedFiltering />
        <Table
          rowComponent={TableRow}
          columnExtensions={columnExtensions}
          height="68vh"
        />

        <TableColumnResizing
          onColumnWidthsChange={setColumnWidths}
          columnWidths={columnWidths}
          resizingMode="nextColumn"
        />

        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />

        <TableHeaderRow showSortingControls />

        <TableColumnVisibility
          hiddenColumnNames={hiddenColumns}
          onHiddenColumnNamesChange={setHiddenColumns}
        />

        <Toolbar />
        <ColumnChooser toggleButtonComponent={toggleButtonComponent} />
        <ToolbarOptions
          dialogTitle={dialogTitle}
          formDialog={formDialog}
          group={group}
          columnGroup={columnGroup}
          onColumnGroupChange={setColumnGroup}
          refresh={refresh}
          loading={loading}
        />

        {HeaderToolbar(headerIcon, headerTitle)}
        {role === 'doctor' && (
          <TableFilterRow
            showFilterSelector
            cellComponent={FilterCell}
            iconComponent={FilterIcon}
          />
        )}
        <TableFixedColumns rightColumns={rightColumns} />

        {contentComponent && (
          <TableRowDetail
            contentComponent={contentComponent}
            cellComponent={DetailCell}
          />
        )}
        <DetailEditCell />

        <ActionColumns actionColumns={actionColumns} />
        <PagingPanel pageSizes={pageSizes} />
        {loading && <GridSpinner />}
      </Grid>
    </Paper>
  )
}
