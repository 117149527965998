import { MenuItem, Select, TableCell, withStyles } from '@material-ui/core'
import { MedicalOrderStatusEnum } from '@services/api'
import { FormattedMessage, useIntl } from 'react-intl'

const StudyStatusFilterCellBase = ({ filter, onFilter }: any) => {
  const intl = useIntl()
  return (
    <TableCell>
      <Select
        id="select"
        displayEmpty
        value={filter ? filter.value : ''}
        onChange={(e) =>
          onFilter(
            e.target.value
              ? { value: e.target.value, operation: 'equal' }
              : null,
          )
        }
        fullWidth
      >
        <MenuItem value="">
          <em>
            <FormattedMessage id="all" />
          </em>
        </MenuItem>
        {Object.keys(MedicalOrderStatusEnum)
          .map((key) => MedicalOrderStatusEnum[key])
          .map((item, index: number) => (
            <MenuItem key={index} value={item}>
              <em>
                {item && intl.formatMessage({ id: 'enums.study.' + item })}
              </em>
            </MenuItem>
          ))}
      </Select>
    </TableCell>
  )
}
const stylesStudyStatusFilter = () => ({})

export default withStyles(stylesStudyStatusFilter, {
  name: 'StudyStatusFilterCell',
})(StudyStatusFilterCellBase)
