import { useState } from 'react'

import { DropzoneDialog } from 'material-ui-dropzone'
import { Typography } from '@material-ui/core'
import { truncate } from 'lodash'

import { EcSpaceHorizontal } from '@utils/Spacing'
import { makeStyles } from '@material-ui/styles'

interface Props {
  accept?: string[]
  numberFile?: number
  nameFile: string
  color?: string
  onSave(files: File[]): void
}

const useStyles = makeStyles({
  icon: {
    fontWeight: 500,
    padding: '0px 5px 2px 5px',
    borderRadius: 2,
  },
})

export function DialogFileInput({
  accept = ['image/*', 'application/pdf'],
  numberFile = 1,
  nameFile,
  onSave,
}: Props) {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  console.log('nameFile', nameFile)

  return (
    <div className="dialog-file-input">
      <div className="button-upload" onClick={() => setOpen(true)}>
        <i className={`fas fa-file-upload ${classes.icon}`}></i>
        <EcSpaceHorizontal />
        <Typography>
          {truncate(nameFile, { length: 55, separator: '..' })}
        </Typography>
      </div>

      <DropzoneDialog
        acceptedFiles={accept}
        filesLimit={numberFile}
        cancelButtonText={'cancel'}
        submitButtonText={'submit'}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        /*
        onSave={(file) => {
          console.log('Files:', file)
          setOpen(false)
        }}
        */
        onSave={(files) => {
          onSave(files)
          setOpen(false)
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </div>
  )
}
