import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useSelector } from '@state/store'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, PrimaryButton } from '@components/buttons'
import BadgeCps from '../../assets/img/logos/Badge_Sombre_0.png'

import { loginSchema } from '@utils/schemas'
import { AuthCard } from '../../components/cards/AuthCard'
import { getCaptcha, login, loginCPS } from '@state/thunks/authThunk'
import { LoginDto } from '@services/api'
import { OpenIdService } from '@services/openId'
import { useQuery } from '@hooks/useQuery'
import {
  CaptchaInput,
  PasswordInput,
  TextInput,
} from '@ether-healthcare/inputs'
import { Text } from '@components/texts'
const useStyles = makeStyles({
  grid: {
    padding: '0px 20px',
  },
})

export default function Login() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const query = useQuery()
  const { captcha, loading } = useSelector(({ auth, loading }) => ({
    captcha: auth.captcha,
    loading: loading['auth/login'],
  }))
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDto>({
    defaultValues: {
      captcha: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema) as any,
  })

  const handleCPS = useCallback(() => {
    try {
      OpenIdService.auth()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const onSubmit = (values: LoginDto) => {
    dispatch(
      login({
        captcha: values.captcha,
        email: values.email.toLowerCase().trim(),
        password: values.password.trim(),
      }),
    )
  }
  useEffect(() => {
    if (location && location.search) {
      const session_state = query.get('session_state')
      const code = query.get('code')
      if (code && session_state) {
        dispatch(loginCPS(code))
      }
    }
  }, [location])

  const getAuthCaptcha = () => {
    dispatch(getCaptcha())
  }

  useEffect(() => {
    getAuthCaptcha()
  }, [])

  const Content = (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.grid}
    >
      <Grid item xs={4}>
        <Divider
          variant="middle"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}
        />
      </Grid>
      <Grid item>
        <Text
          text="patient"
          style={{
            color: 'white',
            textTransform: 'uppercase',
          }}
          format
        />
      </Grid>
      <Grid item xs={4}>
        <Divider
          variant="middle"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextInput
          type="email"
          name="email" // {...register('email')}
          label="Email"
          variant="filled"
          errors={errors}
          control={control}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <PasswordInput
          name="password" // {...register('password')}
          label="Password"
          variant="filled"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item container justifyContent="center" xs={12} sm={12}>
        <CaptchaInput
          captcha={captcha}
          getCaptcha={getAuthCaptcha}
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <PrimaryButton
          type="submit"
          text="login"
          loading={loading}
          fullWidth
          // color="primary"
          format
        />
      </Grid>
      <Grid item xs={4}>
        <Divider
          variant="middle"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.35)' }}
        />
      </Grid>

      <Grid item xs={4}>
        <Text
          text="healthPro"
          style={{
            color: 'white',
            textTransform: 'uppercase',
          }}
          format
        />
      </Grid>

      <Grid item xs={4}>
        <Divider
          variant="middle"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.35)' }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          text="login-pro-sante-connect"
          fullWidth
          onClick={handleCPS}
          style={{ background: '#000091', padding: 8 }}
          startIcon={
            <img src={BadgeCps} alt="pro-sante-connect" style={{ width: 20 }} />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider
          variant="middle"
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            marginTop: '10px',
          }}
        />
      </Grid>
    </Grid>
  )
  const Actions = (
    <Grid
      container
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      direction="row"
    >
      <Button
        link="/auth/forgotten-password"
        text="forgot-password"
        textColor="textSecondary"
        format
      />
      <Button
        link="/auth/first-connexion"
        text="first-connexion"
        textColor="textSecondary"
        format
      />
    </Grid>
  )
  return (
    <AuthCard>
      <AuthCard.Header title="login" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions>{Actions}</AuthCard.Actions>
    </AuthCard>
  )
}
