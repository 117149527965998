import {
  Getter,
  Getters,
  Plugin,
  Template,
  TemplateConnector,
} from '@devexpress/dx-react-core'
import { IconButton, makeStyles, TableCell, Tooltip } from '@material-ui/core'
import {
  Table,
  TableHeaderRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import { TableColumn } from '@devexpress/dx-react-grid'

const useStyles = makeStyles({
  iconBtn: {
    verticalAlign: 'middle',
  },
})

function makeDictionary<T>(values: T[], getKey: (value: T) => string) {
  return values.reduce((acc, v) => {
    acc[getKey(v)] = v
    return acc
  }, {} as { [key: string]: T })
}

const pluginDependencies = [{ name: 'Table' }]
export interface IActionColumn {
  columnName: string
  icon: JSX.Element //React.ReactElement<any>
  disabled?: (row: any) => boolean
  label?: string
  onClick?: (row: any) => void
  onMouseEnter?: (row: any) => void
  onMouseLeave?: (row: any) => void
}
export interface IActionColumnsProps {
  actionColumns: IActionColumn[]
}
const ACTION_COLUMN_TYPE = Symbol('ACTION_COLUMN')

export function ActionColumns({ actionColumns }: IActionColumnsProps) {
  const classes = useStyles()

  const columnDictionary = makeDictionary(actionColumns, (i) => i.columnName)
  const computeColumns = (
    actionColumns: { [key: string]: IActionColumn },
    getters: Getters,
  ) => {
    const tableColumns = getters.tableColumns as TableColumn[]
    const columns = tableColumns.map((tableColumn) => {
      if (!tableColumn.column || !actionColumns[tableColumn.column.name]) {
        return tableColumn
      }
      return { ...tableColumn, type: ACTION_COLUMN_TYPE, width: 60 }
    })
    return columns
  }
  const isActionTableCell = (params: any) => {
    if (
      (params.tableRow.type === Table.ROW_TYPE ||
        params.tableRow.type === VirtualTable.ROW_TYPE) &&
      params.tableColumn.type === ACTION_COLUMN_TYPE
    ) {
      return true
    }
    return false
  }
  const isActionTableHeader = (params: any) => {
    if (
      params.tableRow.type === TableHeaderRow.ROW_TYPE &&
      params.tableColumn.type === ACTION_COLUMN_TYPE
    ) {
      return true
    }
    return false
  }
  return (
    <Plugin name="ActionColumn" dependencies={pluginDependencies}>
      <Getter
        name="tableColumns"
        computed={computeColumns.bind(null, columnDictionary)}
      />
      <Template name="tableCell" predicate={isActionTableHeader.bind(null)}>
        <TemplateConnector>{() => <TableCell />}</TemplateConnector>
      </Template>
      <Template name="tableCell" predicate={isActionTableCell.bind(null)}>
        {(params: any) => (
          <TemplateConnector>
            {() => {
              const actionColumn =
                columnDictionary[params.tableColumn.column.name]
              const button = (
                <IconButton
                  size="small"
                  color={actionColumn.disabled ? undefined : 'primary'}
                  aria-label={actionColumn.label}
                  className={classes.iconBtn}
                  disabled={
                    actionColumn.disabled
                      ? actionColumn.disabled.bind(null, params.tableRow.row)()
                      : undefined
                  }
                  onClick={
                    actionColumn.onClick
                      ? actionColumn.onClick.bind(null, params.tableRow.row)
                      : undefined
                  }
                  onMouseLeave={
                    actionColumn.onMouseLeave
                      ? actionColumn.onMouseLeave.bind(
                        null,
                        params.tableRow.row,
                      )
                      : undefined
                  }
                  onMouseEnter={
                    actionColumn.onMouseEnter
                      ? actionColumn.onMouseEnter.bind(
                        null,
                        params.tableRow.row,
                      )
                      : undefined
                  }
                >
                  {actionColumn.icon}
                </IconButton>
              )
              if (actionColumn.label && !actionColumn.disabled) {
                return (
                  <TableCell align="right">
                    <Tooltip title={actionColumn.label}>{button}</Tooltip>
                  </TableCell>
                )
              } else {
                return <TableCell align="right">{button}</TableCell>
              }
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  )
}
