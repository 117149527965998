import { TooltipButton } from '@components/buttons'
import { Share } from '@material-ui/icons'
import {
  AccountDto,
  MedicalOrderDto,
  AccountRoleEnum,
  SharingPermissionEnum,
} from '@services/api'
import { isSharingExpired } from '@utils/utils'
type ShareTooltipProps = {
  study: MedicalOrderDto
  onClick: () => void
  account?: AccountDto
}

export function ShareTooltip({ study, onClick, account }: ShareTooltipProps) {
  const canShare = () => {

    if (account?.role === AccountRoleEnum.patient) {
      return true
    }
    if (account?.role === AccountRoleEnum.doctor && study.reports.length > 0) {
      const share = study.shares.find((share) => share.accountId === account.id)
      if (
        share &&
        share.permission === SharingPermissionEnum.SHARE &&
        !isSharingExpired(share.startDate, share.expiration)
      ) {
        return true
      }
    }
    return false
  }

  return (
    <TooltipButton
      title="share"
      disable={!canShare()}
      onClick={onClick}
      icon={<Share />}
    />
  )
}
