import { MedicalOrderDto } from './api'
import crypto from 'crypto'

export const openViewer = async ({ studyInstanceUID }: MedicalOrderDto) => {
  const shared_secret = process.env.REACT_APP_RADIO_VIEWER_SECRET
  const expiry_period = 3600
  const current_time = Date.now()
  const expiry_time = current_time + expiry_period
  const content = ['authtoken', expiry_time].join(':')
  const secret_content = [content, shared_secret].join(':')
  const client_hash = crypto
    .createHash('sha256')
    .update(secret_content, 'utf8')
    .digest('hex')
  const content_and_hash = [content, client_hash].join(':')
  const token = encodeURIComponent(btoa(content_and_hash))

  //TODO Add condition if doctor use ResMD else use OHIF
  try {
    const url = `${process.env.REACT_APP_RADIO_VIEWER_URL}/launch?username=authtoken&password=${token}&action=view&StudyInstanceUID=${studyInstanceUID}`
    const win = window.open(url, '_blank')
    if (win) {
      win.focus()
    } else {
      throw new Error('OPEN_VIEWER_ERROR')
    }
  } catch (e) {
    console.log(e)
  }
}
