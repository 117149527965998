import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordInput, TextInput } from '@ether-healthcare/inputs'
import { Button } from '@components/buttons'

import { AppDispatch, useSelector } from '@state/store'
import { registerSchema } from '@utils/schemas'

import { AuthCard } from '../../components/cards/AuthCard'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { registerUser } from '@state/thunks/authThunk'
import { useQuery } from '@hooks/useQuery'
const useStyles = makeStyles({
  img: {
    backgroundColor: 'white',
  },
  gridItem: {
    backgroundColor: 'white',
    marginBottom: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    padding: '0px 20px',
  },
})

interface Values {
  email: string
  newPassword: string
  confirmNewPassword: string
  accessionNumber: string
  birthDate: string
}

export default function RegisterPatient() {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  const loading = useSelector(({ loading }) => loading['auth/register/patient'])
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Values>({
    resolver: yupResolver(registerSchema),
  })

  const onSubmit = (values: Values) => {
    dispatch(
      registerUser({
        birthDate: values.birthDate,
        accessionNumber: values.accessionNumber,
        email: values.email.toLowerCase().trim(),
        password: values.newPassword.trim(),
      }),
    ).then(() => navigate('/auth/login'))
  }

  useEffect(() => {
    if (location && location.search) {
      const id = query.get('id')
      const b = query.get('b')
      if (id && b) {
        // register({ name: 'accessionNumber' })
        // register({ name: 'birthDate' })
        setValue('accessionNumber', id)
        setValue('birthDate', b)
      } else {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: 'Invalid user informations',
          }),
        )
        navigate('/auth/login')
      }
    } else {
      navigate('/auth/login', { replace: true })
    }
  }, [dispatch, history, location, query, control, setValue])

  const Content = (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.grid}
    >
      <Grid item xs={12} sm={12}>
        <TextInput
          label="email"
          name="email"
          errors={errors}
          control={control}
          variant="filled"
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <PasswordInput
          name="newPassword"
          label="Password"
          variant="filled"
          errors={errors}
          control={control}
          autoComplete="new-password"
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <PasswordInput
          name="confirmNewPassword"
          variant="filled"
          label="Password"
          errors={errors}
          control={control}
          autoComplete="new-password"
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          color="primary"
          format
        />
      </Grid>
    </Grid>
  )

  return (
    <AuthCard>
      <AuthCard.Header title="register" />
      <AuthCard.Content>{Content}</AuthCard.Content>
      <AuthCard.Actions backToLoginLink />
    </AuthCard>
  )
}
