import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { PasswordInput } from '@ether-healthcare/inputs'
import { Button } from '@components/buttons'

import { AccountDto } from '@services/api'
import { resetPasswordSchema } from '@utils/schemas'
import { Text } from '../texts'
import { updatePassword } from '@state/thunks/authThunk'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type Values = {
  newPassword: string
  confirmNewPassword: string
}
type UpdatePasswordFormProps = {
  account?: AccountDto
}

export function UpdatePasswordForm({ account }: UpdatePasswordFormProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  })

  const onSubmit = (values: Values) => {
    if (account) dispatch(updatePassword({ body: values, id: account.id }))
  }
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-lock" />
            </Grid>
            <Grid item>
              <Text text="password" variant={'h6'} format />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              name="newPassword"
              placeholder="New Password"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button
              text="submit"
              onClick={handleSubmit(onSubmit)}
              format
              color="primary"
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
