import { useSelector } from '@state/store'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import Footer from '@components/Footer'
import { makeStyles } from '@material-ui/styles'
import video from '../assets/video/video.mp4'

const useStyles = makeStyles({
  container: {
    background: '#0c0c0c',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    zIndex: 1,
  },
  background: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    filter: 'brightness(70%)',
  },
  video: {
    width: '100%',
    height: '100%',
    oObjectFit: 'cover',
    objectFit: 'cover',
    overflow: 'hidden',
  },
  outlet: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    zIndex: 2,
  },
})

export default function AuthLayout() {
  const classes = useStyles()
  const location = useLocation()
  const isAuth = useSelector(({ auth }) => auth.isAuth)
  if (isAuth) return <Navigate to="/" state={{ from: location }} replace />

  return (
    <>
      <main className={classes.container}>
        <div className={classes.background}>
          <video autoPlay muted src={video} className={classes.video}></video>
        </div>
        <div className={classes.outlet}>
          <Outlet />
          <Footer />
        </div>
      </main>
    </>
  )
}
