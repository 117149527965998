import { useIntl } from 'react-intl'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'

import { TextField, TextFieldProps, Box, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { DoctorDto, DoctorService } from '@services/api'
import { throttle } from 'lodash'
import { useState, useMemo, useEffect } from 'react'
import { Text } from '@components/texts'

type DoctorAutocompleteByNameInputProps = {
  errors?: DeepMap<any, FieldError>
  name: string
  control: Control<any>
  defaultValue?: unknown
}

const useStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize',
  },
})
export function DoctorAutocompleteByNameInput({
  errors,
  name,
  control,
  defaultValue,
}: DoctorAutocompleteByNameInputProps) {
  const intl = useIntl()
  const [options, setOptions] = useState<DoctorDto[]>([])
  const [inputValue, setInputValue] = useState('')

  const classes = useStyles()

  const autocompleteByName = async (name: string) => {
    const doctors = await DoctorService.autocompleteByName({ name })
    setOptions(doctors)
  }

  const fetch = useMemo(
    () =>
      throttle((inputValue: string) => {
        autocompleteByName(inputValue.toLowerCase())
      }, 200),
    [],
  )

  useEffect(() => {
    if (inputValue === '') {
      setOptions([])
      return undefined
    }
    fetch(inputValue)
  }, [inputValue, fetch])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { onChange }, ...props }) => {
        return (
          <Autocomplete
            {...props}
            fullWidth
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            autoComplete
            includeInputInList
            filterOptions={(x) => x}
            options={options}
            getOptionLabel={(option) =>
              option ? `${option.firstName} ${option.lastName}` : ''
            }
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={({
              firstName,
              lastName,
              profession,
              skill,
              category,
              address,
            }) => {
              return (
                <Box p={0} m={0}>
                  <Text
                    text={
                      firstName.charAt(0).toUpperCase() +
                      firstName.slice(1) +
                      ' ' +
                      lastName.charAt(0).toUpperCase() +
                      lastName.slice(1)
                    }
                    variant="h6"
                  />
                  <i className={classes.capitalize}>
                    {profession !== 'Médecin'
                      ? profession
                      : skill
                        ? skill
                        : category}{' '}
                    {address && `(${address})`}
                  </i>
                </Box>
              )
            }}
            onChange={(_, value) => {
              setOptions(value ? [value, ...options] : options)
              onChange(value ? value : '')
            }}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                size="small"
                variant="filled"
                InputLabelProps={{
                  style: {
                    color: '#464855',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    color: 'rgba(0, 0, 0, 0.38)',
                    backgroundColor: '#fff',
                  },
                }}
                name={name}
                label={name && intl.formatMessage({ id: name })}
                placeholder={name && intl.formatMessage({ id: name })}
                error={errors && !!errors[name]}
                helperText={
                  errors &&
                  errors[name] &&
                  intl.formatMessage({ id: errors[name].message })
                }
              />
            )}
          />
        )
      }}
    />
  )
}
