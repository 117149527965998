import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui'
import { AccessTime, DateRange } from '@material-ui/icons'

/**
 * Change Filter Icon for the props `availableFilterOperations` on `DataTypeProvider` component
 * Used by `iconComponent` in `TableFilterRow`
 * @param type
 * @returns
 */
export const FilterIcon = ({ type, ...restProps }: { type: string }) => {
  switch (type) {
    case 'month':
    case 'date':
      return <DateRange {...restProps} />
    case 'time':
      return <AccessTime {...restProps} />
    default:
      return <TableFilterRow.Icon type={type} {...restProps} />
  }
}
