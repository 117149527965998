import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { AutoLogoutDialog, ConsentDialog } from '@components/dialogs'
import Navbar from '@components/NavBar'
import Footer from '@components/Footer'
import { Avatar, Backdrop, Theme, makeStyles } from '@material-ui/core'

import Logo from '../assets/img/logos/logo.png'

// import ConfirmMobileModal from '@containers/app/Confir mMobileModal'
// import useWindowSize from '@hooks/useWindowSize'

import { AccountDto } from '@services/api'
import { findMyInfos, logout } from '@state/thunks/authThunk'
import { consentFulfilled } from '@state/reducers/authReducer'
import { throttle } from 'lodash'
import * as Sentry from '@sentry/react'
const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  animatedLogo: {
    animation: '$animation 2s ease-in-out alternate infinite',
  },
  '@keyframes animation': {
    from: {
      transform: 'scale(1)',
    },
    to: {
      transform: 'scale(2)',
    },
  },
  main: {
    flex: 1,
    paddingTop: 2.5,
  },
}))
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
export default function AppLayout() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const {
    isAuth,
    account,
    loading,
    hasConsent,
    // mobileConfirmed,
  } = useSelector(({ auth, loading }) => ({
    isAuth: auth.isAuth,
    account: auth.account as AccountDto, //Account will exist
    loading: loading['user/findMyInfos'],
    hasConsent: auth.hasConsent,
    //TODO phone
    mobileConfirmed: true, //auth.user.isPhoneConfirmed,
  }))

  let logoutTimeout: NodeJS.Timeout | undefined

  /**
   * Clear the time out if it exists
   */
  const clearTimeOut = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  /**
   * Set the time out with the account infos
   */
  const setTimeOut = () => {
    if (account)
      logoutTimeout = setTimeout(
        () => setOpen(true),
        account.security.inactivityTimeout * 60 * 1000 || 60 * 1000,
      )
  }

  /**
   * Clear the time out and set a new one
   */
  const resetTimeout = () => {
    clearTimeOut()
    setTimeOut()
  }

  const handleLogout = () => {
    clearTimeOut()
    dispatch(logout())
  }
  const handleStayLogged = () => {
    resetTimeout()
    setOpen(false)
  }

  const setConsent = () => dispatch(consentFulfilled())
  const handleOnSign = () => {}

  useEffect(() => {
    if (isAuth) dispatch(findMyInfos())
  }, [])

  const throttledResetTimeout = useCallback(
    throttle(() => resetTimeout(), 200),
    [],
  )
  useEffect(() => {
    for (const i in events) {
      window.addEventListener(events[i], throttledResetTimeout)
    }
    setTimeOut()
    return () => {
      clearTimeOut()
      for (const i in events) {
        window.removeEventListener(events[i], throttledResetTimeout)
      }
    }
  }, [])

  useEffect(() => {
    if (Number(logoutTimeout) < 0) {
      handleLogout()
      Sentry.captureMessage('Something went wrong', {
        contexts: {
          params: { logoutTimeout: logoutTimeout?.toString() },
        },
      })
    }
  }, [logoutTimeout])

  if (!isAuth) {
    return <Navigate to="auth/login" state={{ from: location }} replace />
  }
  if (loading)
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <Avatar src={Logo} alt="logo" className={classes.animatedLogo} />
      </Backdrop>
    )
  return (
    <>
      <Navbar />
      <main
        className={classes.main}
      >
        <Outlet />
      </main>
      <Footer />
      <AutoLogoutDialog
        open={open}
        onClose={() => setOpen(false)}
        handleStayLogged={handleStayLogged}
        handleLogout={handleLogout}
      />
      <ConsentDialog
        open={!hasConsent}
        setOpen={setConsent}
        onSign={handleOnSign}
      />
    </>
  )
}
