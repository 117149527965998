import { isMobile } from 'react-device-detect'

import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { Box, Icon, makeStyles } from '@material-ui/core'

import { Text } from '../texts'

const useStyles = makeStyles({
  icon: {
    marginRight: '1.5rem',
  },
})

export function HeaderToolbar(headerIcon?: string, headerText?: string) {
  const classes = useStyles()

  return (
    <Plugin name="customToolbarMarkup">
      <Template name="toolbarContent">
        <Box display="contents">
          {headerIcon && (
            <Icon className={`${headerIcon} ${classes.icon}`} color="primary" />
          )}
          {headerText && (
            <Text
              variant={isMobile ? 'h5' : 'h4'}
              text={headerText}
              format
              color="primary"
            />
          )}
        </Box>
        <TemplatePlaceholder />
      </Template>
    </Plugin>
  )
}
