import { TooltipButton } from '@components/buttons'
import { Icon } from '@material-ui/core'
import { openViewer } from '@services/viewer'
import { AccountDto, MedicalOrderDto } from '@services/api'

type ImageTooltipProps = {
  study: MedicalOrderDto
  account?: AccountDto
}

export function ImageTooltip({ study }: ImageTooltipProps) {
  return (
    <TooltipButton
      title="images"
      onClick={() => openViewer(study)}
      disable={!study.radiologyStudy}
      color={study.radiologyStudy ? 'primary' : undefined}
      icon={<Icon fontSize="small" className="fa fa-x-ray" />}
    />
  )
}
