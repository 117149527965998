/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios'

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any
  headers?: any
  url?: any
  data?: any
  params?: any
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void,
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any,
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url }
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  }
  return configs
}

export const basePath = ''

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[]
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[]
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number
  items?: T[]
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number
  items?: T[]
}

// customer definition
// empty

export class PatientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PatientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdatePatientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateReferringDoctor(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdatePatientReferringDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}/referring-doctor'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class DoctorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static autocompleteByName(
    params: {
      /**  */
      name: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/autocomplete/name'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { name: params['name'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Doctor> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class DirectoryService {
  /**
   *
   */
  static autocompleteByName(
    params: {
      /**  */
      name: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RppsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/directory/autocomplete/name'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { name: params['name'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static autocompleteByRpps(
    params: {
      /**  */
      rppsNumber: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SavoirFaireDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/directory/autocomplete/rpps'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { rppsNumber: params['rppsNumber'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class MedicalOrderService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MedicalOrderDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: UpdateMedicalOrderDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<UpdateMedicalOrderDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static getTicketNumber(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}/ticket-number'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static download(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}/download'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static shareWithGuest(
    params: {
      /** requestBody */
      body?: CreateShareWithGuestDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Shares> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/share/email'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static unlockGuestShare(
    params: {
      /** requestBody */
      body?: UnlockGuestShareDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ShareDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/share/unlock'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static share(
    params: {
      /** requestBody */
      body?: CreateShareDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ShareDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/share'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static unshare(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}/unshare'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findSharesByMedicalOrderId(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MedicalOrder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}/share'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static uploadPrescription(
    params: {
      /**  */
      id: number
      /**  */
      file: any
      /**  */
      type: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}/prescription'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )

      let data = null
      data = new FormData()

      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }
      data.append('fileType', params.type)
      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static deletePrescription(
    params: {
      /**  */
      id: number

      /**  */
      prescriptionId: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/medical-order/{id}/prescription/{prescriptionId}'
      url = url.replace('{id}', params['id'] + '')
      url = url.replace('{prescriptionId}', params['prescriptionId'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
export class NotificationService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static read(
    params: {
      ids: number[]
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notification'

      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        url,
        options,
      )

      let data = params.ids

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class GedService {
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/download'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static uploadFile(
    params: {
      /**  */
      id: number
      /**  */
      type: string
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<GedFileDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/upload'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )
      configs.params = { type: params['type'] }
      let data = null
      data = new FormData()
      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class UserService {
  /**
   *
   */
  static getPhoneVerificationCheck(
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static sendPhoneVerification(
    params: {
      /** requestBody */
      body?: CreatePhoneAuthMethod
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static phoneVerify(
    params: {
      /** requestBody */
      body?: PhoneVerifyRequest
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/verify'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findMyInfo(options: IRequestOptions = {}): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class AuthService {
  /**
   *
   */
  static getCaptcha(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/login'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static loginCps(
    params: {
      /** requestBody */
      body?: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/cps/login'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/logout'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static isValidFirstConnection(
    params: {
      /**  */
      accessionNumber: string
      /**  */
      birthDate: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        accessionNumber: params['accessionNumber'],
        birthDate: params['birthDate'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createUserFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterPatientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createDoctorFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/doctor/first-connection'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createGuestFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterGuestDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/guest/first-connection'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createLocalUserFromEmail(
    params: {
      /** requestBody */
      body?: LocalUserRegisterFromMailDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/verify-mail'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static forgottenPassword(
    params: {
      /**  */
      email: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/forgotten-password'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { email: params['email'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: LocalUserResetPasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/reset-password'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: LocalUserUpdatePasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/update-password'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateEmail(
    params: {
      /** requestBody */
      body?: LocalUserForgetPasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/update-email'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export interface LockPassword {
  /**  */
  id: number

  /**  */
  password: string

  /**  */
  account: Account

  /**  */
  accountId: number

  /**  */
  createdAt: Date

  /**  */
  expiredAt: Date
}

export interface LocalAuthMethod {
  /**  */
  lockoutPeriod?: LockoutPeriodEnum

  /**  */
  email: string

  /**  */
  password: string

  /**  */
  verificationUUID?: string

  /**  */
  resetPasswordUUID?: string

  /**  */
  failedAttempt?: number

  /**  */
  failedAttemptTime?: Date

  /**  */
  lockPasswords?: LockPassword[]
}

export interface CpsAuthMethod {
  /**  */
  disable: boolean

  /**  */
  rppsNumber: string

  /**  */
  firstConnection: boolean
}

export interface PhoneAuthMethod {
  /**  */
  phoneNumber: string

  /**  */
  phoneVerificationCode?: string

  /**  */
  isVerified: boolean
}

export interface AuthMethod {
  /**  */
  local: LocalAuthMethod

  /**  */
  cps?: CpsAuthMethod

  /**  */
  phone?: PhoneAuthMethod
}

export interface Reception {
  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface WaitingRoom {
  /**  */
  reception: Reception

  /**  */
  receptionId: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  called: Visit[]

  /**  */
  capacity: number

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface PrepRoom {
  /**  */
  status: object

  /**  */
  isActive: boolean

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface ExamType {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  siteId: number

  /**  */
  site: Site
}

export interface Exam {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: ModalityEnum

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: BodyPartEnum

  /**  */
  ageRange: AgeRangeEnum

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  wc: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  id: number

  /**  */
  type: ExamType

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  examRooms: ExamRoom[]
}

export interface ExamRoom {
  /**  */
  shared: boolean

  /**  */
  xrayCompatible: boolean

  /**  */
  mutualized: boolean

  /**  */
  emergency: boolean

  /**  */
  color: string

  /**  */
  active: boolean

  /**  */
  waitingRoom: WaitingRoom

  /**  */
  waitingRoomId: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  openingTime: Date

  /**  */
  reopeningTime: Date

  /**  */
  breakTime: Date

  /**  */
  closingTime: Date

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  exams: Exam[]

  /**  */
  roomType: number

  /**  */
  processTime: number

  /**  */
  isWheelchairCompatible: boolean

  /**  */
  isStretcherCompatible: boolean

  /**  */
  isAvailable: boolean

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface MedicalEquipment {
  /**  */
  type: EnumMedicalEquipmentType

  /**  */
  model?: string

  /**  */
  manufacturer: string

  /**  */
  commissioningDate: Date

  /**  */
  consoleConstructor: EnumMedicalEquipmentConsoleConstructor

  /**  */
  magnetPower: EnumMedicalEquipmentMagnetPower

  /**  */
  oncology: EnumMedicalEquipmentOncology

  /**  */
  tps: EnumMedicalEquipmentTps

  /**  */
  dose: boolean

  /**  */
  shared: boolean

  /**  */
  stereostatic: boolean

  /**  */
  kvImaging: boolean

  /**  */
  arcTherapy: boolean

  /**  */
  mirrorDevice: boolean

  /**  */
  digital: boolean

  /**  */
  tomosynthese: boolean

  /**  */
  macroMicro: boolean

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface ItEquipment {
  /**  */
  type: string

  /**  */
  ip: string

  /**  */
  mac: string

  /**  */
  port: number

  /**  */
  infos: string

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface Office {
  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface SiteType {
  /**  */
  id: number

  /**  */
  label: string
}

export interface Site {
  /**  */
  primary: boolean

  /**  */
  externalId: string

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  streetAddress: string

  /** Describe the prefered engine for AWS Polly. Engine define speech's type. */
  preferenceSiteEngine: PollyEngineEnum

  /** For a site, describe the prefered language. Store a Language Code for AWS Poly and remember languages available depends of engine type. You can find the languages supported here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/SupportedLanguage.html  */
  preferenceSiteLanguage: string

  /** For a site, describe the prefered voice. Store a VoiceID for AWS Poly and remember voices available depends of engine type and language. You can find the Voices list here : https:\/\/docs.aws.amazon.com\/polly\/latest\/dg\/voicelist.html  */
  preferenceSiteVoice: string

  /**  */
  id: number

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  postalCode: number

  /**  */
  city: string

  /**  */
  country: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  geocode: string

  /**  */
  capacity: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  itEquipments: ItEquipment[]

  /**  */
  examRooms: ExamRoom[]

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  offices: Office[]

  /**  */
  receptions: Reception[]

  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  exams: Exam[]

  /**  */
  examTypes: ExamType[]

  /**  */
  siteTypeId?: number

  /**  */
  siteType: SiteType
}

export interface Member {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: MemberRoleEnum

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  deleteAt: Date
}

export interface SecurityPreferences {
  /**  */
  failureNumber: FailureNumberEnum

  /**  */
  lockoutPeriod: LockoutPeriodEnum

  /**  */
  inactivityTimeout: number

  /**  */
  twoFAMandatory: boolean

  /**  */
  twoFAMethod: TwoFAMethodEnum

  /**  */
  passwordChangesMandatory: boolean

  /**  */
  passwordChangesPeriod: number

  /**  */
  samePasswordAllowed: boolean
}

export interface Viewer {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  brand: string

  /**  */
  nuclear: boolean

  /**  */
  measurement: boolean

  /**  */
  dicom: boolean

  /**  */
  CE: boolean

  /**  */
  gsps: boolean

  /**  */
  sr: boolean
}

export interface ViewerPreferences {
  /**  */
  patientViewer?: Viewer

  /**  */
  patientViewerId: number

  /**  */
  doctorViewer?: Viewer

  /**  */
  doctorViewerId: number

  /**  */
  radioViewer?: Viewer

  /**  */
  radioViewerId: number

  /**  */
  tomoViewer?: Viewer

  /**  */
  tomoViewerId: number

  /**  */
  nuclearViewer?: Viewer

  /**  */
  nuclearViewerId: number
}

export interface SharingPreferences {
  /**  */
  maxPermission: SharingPermissionEnum

  /**  */
  permission: SharingPermissionEnum

  /**  */
  ownershipAccept: boolean

  /**  */
  expiration: SharingExpirationEnum
}

export interface ClientPreferences {
  /**  */
  security: SecurityPreferences

  /**  */
  viewers: ViewerPreferences

  /**  */
  sharing: SharingPreferences
}

export interface Client {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  postalCode: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  id: number

  /**  */
  fax: string

  /**  */
  externalId: string

  /**  */
  logo: string

  /**  */
  sites: Site[]

  /**  */
  members: Member[]

  /**  */
  preferences: ClientPreferences
}

export interface ExternalId {
  /**  */
  id: number

  /**  */
  value: string

  /**  */
  label: string

  /**  */
  description: string

  /**  */
  source: string

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  clientId: number

  /**  */
  client: Client
}

export interface DoctorTable {}

export interface DisplayPreferences {
  /**  */
  home: any
}

export interface DoctorPreferences {
  /**  */
  display: DisplayPreferences
}

export interface Doctor {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  preferences: DoctorPreferences

  /**  */
  exams: Exam[]
}

export interface GedFile {
  /**  */
  id: number

  /**  */
  type: EnumGedFileType

  /**  */
  relatedEntity: number

  /**  */
  filename: string

  /**  */
  mimeType: string
}

export interface Consent {
  /**  */
  status: DocumentStatusEnum

  /**  */
  id: number

  /**  */
  file: GedFile
}

export interface PatientPreferences {
  /**  */
  display: DisplayPreferences
}

export interface Patient {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  referringDoctor: Doctor

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  visits: Visit[]

  /**  */
  studies: MedicalOrder[]

  /**  */
  preferences: PatientPreferences
}

export interface TextToSpeech {
  /** A mp3 file is associate with a waiting room */
  ttsURI: string

  /** Defines the availability of mp3. An mp3 is available for 72 hours in the S3 bucket */
  expireAt: Date

  /** Deleted date manages by typeORM on softdelete function */
  deleteAt: Date

  /**  */
  id: number

  /**  */
  createdAt: Date

  /**  */
  reception: object

  /**  */
  receptionId?: number

  /**  */
  examRoom: object

  /**  */
  examRoomId?: number

  /**  */
  doctorOffice: object

  /**  */
  doctorOfficeId?: number

  /**  */
  prepRoom: object

  /**  */
  prepRoomId?: number

  /**  */
  roomType: EnumTextToSpeechRoomType

  /**  */
  visit: Visit

  /**  */
  visitId: number
}

export interface Visit {
  /**  */
  id: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  visitNumber: string

  /**  */
  studies: MedicalOrder[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  admissionDate: Date

  /**  */
  dischargedDate: Date

  /**  */
  ticketNumber: string

  /**  */
  waitingRoomStatus: EnumVisitWaitingRoomStatus

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  hospitalService: string

  /**  */
  admitSource: EnumVisitAdmitSource

  /**  */
  emergency?: boolean

  /**  */
  dementia?: boolean

  /**  */
  visuallyImpaired?: boolean

  /**  */
  blind?: boolean

  /**  */
  crutches?: boolean

  /**  */
  stretcher?: boolean

  /**  */
  wheelchair?: boolean

  /**  */
  deaf?: boolean

  /**  */
  chatRequest?: boolean

  /**  */
  sawDoctor?: boolean

  /**  */
  speeches: TextToSpeech[]

  /**  */
  cancelReason?: string

  /**  */
  accompanying: number
}

export interface Prescription {
  /**  */
  status: PrescriptionStatusEnum

  /**  */
  id: number

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface Report {
  /**  */
  status: ReportStatusEnum

  /**  */
  id: number

  /**  */
  file: GedFile

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface Manufacturer {
  /**  */
  id: number

  /**  */
  name: string

  /**  */
  modelName: string
}

export interface Instance {
  /**  */
  uid: string

  /**  */
  serie: Serie

  /**  */
  number: number

  /**  */
  uiSopClassUid: string

  /**  */
  studyInstanceUid: string

  /**  */
  createdAt: Date

  /**  */
  contentDate: Date
}

export interface Serie {
  /**  */
  uid: string

  /**  */
  radiologyStudy: RadiologyStudy

  /**  */
  number: number

  /**  */
  laterality: string

  /**  */
  createdDate: Date

  /**  */
  time: Date

  /**  */
  modality: string

  /**  */
  institutionName: string

  /**  */
  description: string

  /**  */
  performingPhysician: string

  /**  */
  manufacturer: Manufacturer

  /**  */
  instances: Instance[]
}

export interface RadiologyStudy {
  /**  */
  uid: string

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  description: string

  /**  */
  imagesNumber: number

  /**  */
  series: Serie[]

  /**  */
  admittingDiagnoseDescription: string

  /**  */
  time: Date
}

export interface MedicalOrder {
  /**  */
  status: MedicalOrderStatusEnum

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  attendingDoctor: Doctor

  /**  */
  referringDoctor: Doctor

  /**  */
  consultingDoctor: Doctor

  /**  */
  prescription: Prescription

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  studyInstanceUID: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: Shares[]

  /**  */
  radiologyStudy: RadiologyStudy
}

export interface Shares {
  /**  */
  permission?: SharingPermissionEnum

  /** */
  expiration: SharingExpirationEnum
  /**  */
  id: number

  /**  */
  startDate: Date

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  medicalOrderId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  accessCode?: string
}

export interface Account {
  /**  */
  role: AccountRoleEnum

  /**  */
  id: number

  /**  */
  authMethod: AuthMethod

  /**  */
  locked?: boolean

  /**  */
  shares: Shares[]
}

export interface DoctorDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  preferences: DoctorPreferences

  /**  */
  exams: Exam[]
}

export interface PatientDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  referringDoctor: DoctorDto

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  visits: Visit[]

  /**  */
  studies: MedicalOrder[]

  /**  */
  preferences: PatientPreferences
}

export interface UpdatePatientDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  ssn?: string

  /**  */
  surName?: string

  /**  */
  externalIds?: ExternalId[]

  /**  */
  birthDate?: Date

  /**  */
  placeOfBirth?: string

  /**  */
  legalPerson?: string

  /**  */
  maritalStatus?: string

  /**  */
  mergedDate?: Date

  /**  */
  mergedPatient?: Patient

  /**  */
  referringDoctor?: Doctor

  /**  */
  blackListedDoctors?: Doctor[]

  /**  */
  ALD?: boolean

  /**  */
  CSS?: boolean

  /**  */
  CMU?: boolean

  /**  */
  consent?: Consent

  /**  */
  visits?: Visit[]

  /**  */
  studies?: MedicalOrder[]

  /**  */
  preferences?: PatientPreferences
}

export interface UpdatePatientReferringDoctorDto {
  /**  */
  shareOld: boolean

  /**  */
  shareNew: boolean

  /**  */
  rppsNumber: string
}

export interface CreateDoctorDto {
  /**  */
  accountId: number

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  exams: Exam[]
}

export interface UpdateDoctorDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  rppsNumber?: string

  /**  */
  title?: string

  /**  */
  category?: string

  /**  */
  skill?: string

  /**  */
  profession?: string

  /**  */
  preferences?: DoctorPreferences

  /**  */
  exams?: Exam[]
}

export interface RppsDto {
  /**  */
  id: number

  /**  */
  rppsNumber: string

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  profession: string

  /**  */
  category: string
  skillLabel: string
  categoryLabel: string
  professionLabel: string

  /**  */
  skill: string

  /**  */
  skillType: string

  /**  */
  streetAddress: string

  /**  */
  zipCode: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  fax: string

  /**  */
  email: string

  /**  */
  createAt: Date

  /**  */
  updateAt: Date

  /**  */
  deletedAt: Date
}

export interface SavoirFaireDto {
  /**  */
  id: number

  /**  */
  rppsNumber: string

  /**  */
  lastName: string

  /**  */
  firstName: string

  /**  */
  categoryLabel: string

  /**  */
  categoryCode: string

  /**  */
  professionLabel: string

  /**  */
  professionCode: string

  /**  */
  skillLabel: string

  /**  */
  skillCode: string

  /**  */
  skillTypeLabel: string

  /**  */
  skillTypeCode: string

  /**  */
  createAt: number

  /**  */
  updateAt: number

  /**  */
  deletedAt: number
}

export interface MedicalOrderDto {
  /**  */
  status: MedicalOrderStatusEnum

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  attendingDoctor: Doctor

  /**  */
  referringDoctor: Doctor

  /**  */
  consultingDoctor: Doctor

  /**  */
  prescription: Prescription

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  studyInstanceUID: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: Shares[]

  /**  */
  radiologyStudy: RadiologyStudy

  /** */
  documents: Document[]
}

export interface Document {
  /** */
  id: number

  /** */
  fileId: number

  /** */
  medicalOrderId: number

  /** */
  fileType: FileTypeEnum

  /** */
  gedFile: GedFile

  /** */
  url: string
}

export interface UpdateMedicalOrderDto {
  /**  */
  status?: MedicalOrderStatusEnum

  /**  */
  id?: number

  /**  */
  visit?: Visit

  /**  */
  visitId?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  patient?: Patient

  /**  */
  patientId?: number

  /**  */
  attendingDoctor?: Doctor

  /**  */
  referringDoctor?: Doctor

  /**  */
  consultingDoctor?: Doctor

  /**  */
  prescription?: Prescription

  /**  */
  consent?: Consent

  /**  */
  reports?: Report[]

  /**  */
  exam?: Exam

  /**  */
  examRoom?: ExamRoom

  /**  */
  examRoomId?: number

  /**  */
  examId?: number

  /**  */
  plannedDate?: Date

  /**  */
  editedDate?: Date

  /**  */
  cancelDate?: Date

  /**  */
  description?: string

  /**  */
  studyInstanceUID?: string

  /**  */
  accessionNumber?: string

  /**  */
  service?: string

  /**  */
  shares?: Shares[]

  /**  */
  radiologyStudy?: RadiologyStudy
}

export interface CreateShareWithGuestDto {
  rppsNumber?: string

  /**  */
  permission?: SharingPermissionEnum

  /**  */
  email: string

  /**  */
  expiration?: SharingExpirationEnum

  /**  */
  medicalOrderId: number
}

export interface UnlockGuestShareDto {
  /**  */
  id: number

  /**  */
  accessCode?: string
}

export interface ShareDto {
  /**  */
  permission?: SharingPermissionEnum

  /**  */
  id: number

  /**  */
  startDate: Date

  /**  */
  endDate?: Date

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  medicalOrderId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  accessCode?: string
}

export interface CreateShareDto {
  /**  */
  permission?: SharingPermissionEnum

  /**  */
  expiration?: SharingExpirationEnum

  /**  */
  accountId: number

  /**  */
  medicalOrderId: number

  /**  */
  accessCode?: string
}

export interface GedFileDto {
  /**  */
  id: number

  /**  */
  type: EnumGedFileDtoType

  /**  */
  relatedEntity: number

  /**  */
  filename: string

  /**  */
  mimeType: string
}

export interface CreatePhoneAuthMethod {
  /**  */
  phoneNumber: string
}

export interface PhoneVerifyRequest {
  /**  */
  verificationCode: string
}

export interface User {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string
}

export interface LoginDto {
  /**  */
  email: string

  /**  */
  password: string

  /**  */
  captcha: string
}

export interface AccountDto {
  /**  */
  role: AccountRoleEnum

  /**  */
  email: string

  /**  */
  client: number

  /**  */
  phone: string

  /**  */
  security: SecurityPreferences

  /**  */
  id: number

  /**  */
  locked?: boolean
}

export interface LoginResDto {
  /**  */
  token?: string

  /**  */
  user: AccountDto
}

export interface LocalUserRegisterPatientDto {
  /**  */
  email?: string

  /**  */
  password?: string

  /**  */
  accessionNumber: string

  /**  */
  birthDate: string
}

export interface LocalUserRegisterDoctorDto {
  /**  */
  email: string

  /**  */
  password?: string

  /**  */
  rppsNumber: string
}

export interface LocalUserRegisterGuestDto {
  verificationUUID: string
  id: number
  password: string
  lastname: string
  firstName: string
}
export interface LocalUserRegisterFromMailDto {
  /**  */
  userId: string

  /**  */
  verificationUUID: string
}

export interface LocalUserResetPasswordDto {
  /**  */
  newPassword: string

  /**  */
  resetPasswordUUID: string
}

export interface LocalUserUpdatePasswordDto {
  /**  */
  newPassword: string
}

export interface LocalUserForgetPasswordDto {
  /**  */
  email: string
}

export interface Filter {
  /**  */
  columnName: string

  /**  */
  operation?: string

  /**  */
  value?: string
}

export class NotificationDto {
  id: number
  isRead: boolean
  createdAt: Date
  updatedAt: Date
  type: NotificationTypeEnum
}

export class NewShareNotificationDto extends NotificationDto {
  share: ShareDto
}
export class NewResultAvailableNotification extends NotificationDto {
  medicalOrder: MedicalOrderDto
}

export enum AccountRoleEnum {
  'patient' = 'patient',
  'doctor' = 'doctor',
  'member' = 'member',
  'super-admin' = 'super-admin',
  'guest' = 'guest',
}

export enum LockoutPeriodEnum {
  'TWO_MINUTES' = 'TWO_MINUTES',
  'FIVE_MINUTES' = 'FIVE_MINUTES',
  'FIFTEEN_MINUTES' = 'FIFTEEN_MINUTES',
  'ONE_HOUR' = 'ONE_HOUR',
  'TWELVE_HOURS' = 'TWELVE_HOURS',
  'ONE_DAY' = 'ONE_DAY',
}

export enum SharingPermissionEnum {
  'VIEW' = 'VIEW',
  'REPORT' = 'REPORT',
  'SHARE' = 'SHARE',
  'OWNER' = 'OWNER',
}

export enum SharingExpirationEnum {
  NO_EXPIRATION = 'NO_EXPIRATION',
  'WEEK_1' = 'WEEK_1',
  'WEEK_2' = 'WEEK_2',
  'WEEK_3' = 'WEEK_3',
  'MONTH_1' = 'MONTH_1',
  'MONTH_2' = 'MONTH_2',
  'MONTH_6' = 'MONTH_6',
}

export enum MedicalOrderStatusEnum {
  'available' = 'available',
  'canceled' = 'canceled',
  'completed' = 'completed',
  'discontinued' = 'discontinued',
  'error' = 'error',
  'hold' = 'hold',
  'unspecified' = 'unspecified',
  'replaced' = 'replaced',
  'scheduled' = 'scheduled',
}

export enum SexEnum {
  'F' = 'F',
  'M' = 'M',
  'O' = 'O',
  'U' = 'U',
}

export enum ClientTypeEnum {
  'public' = 'public',
  'private' = 'private',
  'mixed' = 'mixed',
}

export enum PollyEngineEnum {
  'standard' = 'standard',
  'neural' = 'neural',
}

export enum ModalityEnum {
  'AR' = 'AR',
  // 'AS' = 'AS',
  // 'ASMT' = 'ASMT',
  // 'AU' = 'AU',
  'BDUS' = 'BDUS',
  // 'BI' = 'BI',
  'BMD' = 'BMD',
  // 'CD' = 'CD',
  // 'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  // 'CS' = 'CS',
  'CT' = 'CT',
  // 'DD' = 'DD',
  // 'DF' = 'DF',
  // 'DG' = 'DG',
  // 'DM' = 'DM',
  // 'DOC' = 'DOC',
  // 'DS' = 'DS',
  // 'DX' = 'DX',
  // 'EC' = 'EC',
  // 'ECG' = 'ECG',
  // 'EPS' = 'EPS',
  // 'ES' = 'ES',
  // 'FA' = 'FA',
  // 'FID' = 'FID',
  // 'FS' = 'FS',
  // 'GM' = 'GM',
  // 'HC' = 'HC',
  // 'HD' = 'HD',
  // 'IO' = 'IO',
  // 'IOL' = 'IOL',
  // 'IVOCT' = 'IVOCT',
  // 'IVUS' = 'IVUS',
  // 'KER' = 'KER',
  // 'KO' = 'KO',
  // 'LEN' = 'LEN',
  // 'LP' = 'LP',
  // 'LS' = 'LS',
  // 'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  // 'MS' = 'MS',
  'NM' = 'NM',
  // 'OAM' = 'OAM',
  // 'OCT' = 'OCT',
  // 'OP' = 'OP',
  // 'OPM' = 'OPM',
  // 'OPR' = 'OPR',
  // 'OPT' = 'OPT',
  // 'OPV' = 'OPV',
  // 'OSS' = 'OSS',
  'OT' = 'OT',
  // 'PLAN' = 'PLAN',
  // 'PR' = 'PR',
  'PT' = 'PT',
  // 'PX' = 'PX',
  // 'REG' = 'REG',
  // 'RESP' = 'RESP',
  // 'RF' = 'RF',
  // 'RG' = 'RG',
  // 'RTDOSE' = 'RTDOSE',
  // 'RTIMAGE' = 'RTIMAGE',
  // 'RTPLAN' = 'RTPLAN',
  // 'RTRECORD' = 'RTRECORD',
  // 'RTSTRUCT' = 'RTSTRUCT',
  // 'RWV' = 'RWV',
  // 'SEG' = 'SEG',
  // 'SM' = 'SM',
  // 'SMR' = 'SMR',
  // 'SR' = 'SR',
  // 'SRF' = 'SRF',
  // 'ST' = 'ST',
  // 'STAIN' = 'STAIN',
  // 'TG' = 'TG',
  'US' = 'US',
  // 'VA' = 'VA',
  // 'VF' = 'VF',
  // 'XA' = 'XA',
  // 'XC' = 'XC',
}

export enum BodyPartEnum {
  'cerebral' = 'cerebral',
  'skull' = 'skull',
  'pelvis' = 'pelvis',
  'ankle' = 'ankle',
  'elbow' = 'elbow',
  'facial' = 'facial',
  'shoulder' = 'shoulder',
  'knee' = 'knee',
  'hip' = 'hip',
  'leg' = 'leg',
  'hand' = 'hand',
  'maxillary' = 'maxillary',
  'pelvimetry' = 'pelvimetry',
  'foot' = 'foot',
  'wrist' = 'wrist',
  'cervical-spine' = 'cervical-spine',
  'thoracic-spine' = 'thoracic-spine',
  'lumbar-spine' = 'lumbar-spine',
  'sinus' = 'sinus',
  'thoracic' = 'thoracic',
  'abdominal-pelvic' = 'abdominal-pelvic',
  'thoracic-abdominal-pelvic' = 'thoracic-abdominal-pelvic',
  'other' = 'other',
}

export enum AgeRangeEnum {
  'baby' = 'baby',
  'kid' = 'kid',
  'adult' = 'adult',
  'senior' = 'senior',
}

export enum InstructionEnum {
  'underwear-male' = 'underwear-male',
  'underwear-female' = 'underwear-female',
  'no-top' = 'no-top',
  'no-pants' = 'no-pants',
  'no-framed-bra' = 'no-framed-bra',
  'no-belt' = 'no-belt',
  'no-shoes-male' = 'no-shoes-male',
  'no-shoes-female' = 'no-shoes-female',
  'no-jewellery-head' = 'no-jewellery-head',
  'no-jewellery-body' = 'no-jewellery-body',
  'no-jewellery-hand' = 'no-jewellery-hand',
  'no-piercing' = 'no-piercing',
  'blouse' = 'blouse',
}
export enum EnumMedicalEquipmentType {
  'US' = 'US',
  'CT' = 'CT',
  'MR' = 'MR',
  'RCT' = 'RCT',
  'BDUS' = 'BDUS',
  'BMD' = 'BMD',
  'MG' = 'MG',
  'EOS' = 'EOS',
  'PT' = 'PT',
  'NM' = 'NM',
  'LINAC' = 'LINAC',
  'CP' = 'CP',
  'OT' = 'OT',
}
export enum EnumMedicalEquipmentConsoleConstructor {
  'server' = 'server',
  'standalone' = 'standalone',
}
export enum EnumMedicalEquipmentMagnetPower {
  '0.5T' = '0.5T',
  '1T' = '1T',
  '1.5T' = '1.5T',
  '3T' = '3T',
  '7T' = '7T',
  '7T+' = '7T+',
}
export enum EnumMedicalEquipmentOncology {
  'aria' = 'aria',
  'mosaiq' = 'mosaiq',
}
export enum EnumMedicalEquipmentTps {
  'eclipse' = 'eclipse',
  'isogray' = 'isogray',
  'monaco' = 'monaco',
  'xio' = 'xio',
  'pinnacle' = 'pinnacle',
  'brainlab' = 'brainlab',
}
export enum MemberRoleEnum {
  'health-executive' = 'health-executive',
  'executive-director' = 'executive-director',
  'finance-director' = 'finance-director',
  'accountant' = 'accountant',
  'computer-technician' = 'computer-technician',
  'security' = 'security',
  'rssi' = 'rssi',
  'dsi' = 'dsi',
  'secretary' = 'secretary',
  'radiographer' = 'radiographer',
  'service-provider' = 'service-provider',
  'it-provider' = 'it-provider',
  'manager' = 'manager',
  'radiologist' = 'radiologist',
  'head-of-emergency' = 'head-of-emergency',
  'head-of-radiology' = 'head-of-radiology',
  'biomedical-engineer' = 'biomedical-engineer',
  'quality-manager' = 'quality-manager',
  'other' = 'other',
}

export enum FailureNumberEnum {
  'THREE' = 'THREE',
  'FIVE' = 'FIVE',
  'SEVEN' = 'SEVEN',
  'TEN' = 'TEN',
}

export enum TwoFAMethodEnum {
  'sms' = 'sms',
  'email' = 'email',
}

export enum DocumentStatusEnum {
  'validated' = 'validated',
  'refused' = 'refused',
  'processing' = 'processing',
}
export enum EnumGedFileType {
  'prescription' = 'PRESCRIPTION',
  'report' = 'REPORT',
  'id' = 'ID',
  'letter' = 'LETTER',
  'previousExam' = 'PREVIOUS_EXAM',
  'consent' = 'CONSENT',
}
export enum EnumTextToSpeechRoomType {
  'PrepRoom' = 'PrepRoom',
  'ExamRoom' = 'ExamRoom',
  'DoctorOffice' = 'DoctorOffice',
  'WaitingRoom' = 'WaitingRoom',
  'Reception' = 'Reception',
}
export enum EnumVisitWaitingRoomStatus {
  'planned' = 'planned',
  'registered' = 'registered',
  'in-waiting-room' = 'in-waiting-room',
  'in-exam' = 'in-exam',
  'with-doctor' = 'with-doctor',
  'pending-result' = 'pending-result',
  'result-ok' = 'result-ok',
  'discharged' = 'discharged',
  'cancel' = 'cancel',
  'saw-doctor' = 'saw-doctor',
  'preparing_for_exam' = 'preparing_for_exam',
  'pending_reschedule' = 'pending_reschedule',
  'exam_canceled' = 'exam_canceled',
}
export enum EnumVisitAdmitSource {
  'unknown' = 'unknown',
  'physician-referral' = 'physician-referral',
  'clinic-referral' = 'clinic-referral',
  'hm0-referral' = 'hm0-referral',
  'transfer-from-' = 'transfer-from-',
  'emergency-room' = 'emergency-room',
  'concert' = 'concert',
  'information-not-available' = 'information-not-available',
}
export enum PrescriptionStatusEnum {
  'validated' = 'validated',
  'in-progress' = 'in-progress',
  'refused' = 'refused',
}

export enum ReportStatusEnum {
  'in-progress' = 'in-progress',
  'final' = 'final',
  'non-final' = 'non-final',
}
export enum EnumGedFileDtoType {
  'prescription' = 'prescription',
  'report' = 'report',
  'profile' = 'profile',
  'cover' = 'cover',
}

export enum NotificationTypeEnum {
  MEDICAL_ORDER_SHARED = 'MEDICAL_ORDER_SHARED',
  EXAM_RESULT_AVAILABLE = 'EXAM_RESULT_AVAILABLE',
}
