import { useEffect, useRef } from 'react'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'

import { Grid, makeStyles } from '@material-ui/core'

import { AccountRoleEnum, Doctor, MedicalOrderDto } from '@services/api'

import {
  removeStudies,
  setSelected,
  setStudyPagination,
} from '@state/reducers/studyReducer'
import { ColumnType } from '@services/extendedType'
import { IntegrateTable } from '@components/tables'
import { ShareStudyDialog, StudyDialog } from '@components/dialogs'
import { findAll } from '@state/thunks/studyThunk'
import { useIntl } from 'react-intl'
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid'
import {
  updateHiddenColumns,
  updateColumnOrder,
  updateColumnWidths,
} from '@state/reducers/authReducer'
import { throttle } from 'lodash'
import { updateDoctor } from '@state/thunks/authThunk'
import {
  ReportTooltip,
  ReportTooltipMemo,
  ImageTooltip,
  DownloadTooltip,
  ShareTooltip,
} from '@components/tooltips'
import { findNotifications } from '../../../state/thunks/notificationThunk'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  iconBtn: {
    padding: 0,
  },
})

export default function HomeDoctor() {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [reportOpen, setReportOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const notificationsLimit = useRef(10).current

  const { studies, study, loading, user, account } = useSelector(
    ({ study, loading, auth }) => ({
      account: auth.account,
      user: auth.user as Doctor | undefined,
      studies: study.studies,
      study: study.selected,
      loading: loading['study/findAll'],
    }),
  )

  const refresh = () => {
    const limit = studies.size
    const skip = limit * studies.page
    dispatch(
      findAll({
        limit,
        skip,
      }),
    )
  }
  // #region Effects

  useEffect(() => {
    const limit = studies.size
    const skip = limit * studies.page
    dispatch(
      findAll({
        limit,
        skip,
      }),
    )
  }, [studies.page, studies.size])

  useEffect(() => {
    dispatch(findNotifications({ limit: notificationsLimit, skip: 0 }))
    return () => {
      dispatch(removeStudies())
    }
  }, [dispatch])

  //#endregion

  //#region Event handlers

  const openReportDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setReportOpen(true)
  }

  const onpenShareStudyDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setShareOpen(true)
  }

  const handleCurrentPageChange = (page: number) => {
    dispatch(
      setStudyPagination({
        page,
      }),
    )
  }

  const handlePageSizeChange = (size: number) => {
    dispatch(
      setStudyPagination({
        size,
      }),
    )
  }
  //#endregion

  const setHiddenColumns = (hiddenColumns: string[]) => {
    if (user) dispatch(updateHiddenColumns(hiddenColumns))
  }
  const setColumnOrder = (columnOrder: string[]) => {
    if (user) dispatch(updateColumnOrder(columnOrder))
  }
  const setColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
    if (user) dispatch(updateColumnWidths(transformColumnWidth(columnWidths)))
  }

  const throttled = useCallback(
    throttle(() => {
      if (user)
        dispatch(
          updateDoctor({
            id: user.id,
            body: {
              preferences: { display: { home: user.preferences.display.home } },
            },
          }),
        )
    }, 1000),
    [dispatch, user],
  )

  useEffect(() => {
    if (user) throttled()
  }, [user])

  const getAge = ({ patient: { birthDate: date } }: MedicalOrderDto) => {
    const today = new Date()
    const birthDate = new Date(date)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }
  const untransformColumnWidth = (columnWidth: { [key: string]: number }) =>
    Object.entries(columnWidth).map(([columnName, width]) => ({
      columnName,
      width,
    })) as TableColumnWidthInfo[]

  const transformColumnWidth = (columnWidth: TableColumnWidthInfo[]) =>
    columnWidth.reduce(
      (acc, column) => ({ ...acc, [column.columnName]: +column.width }),
      {} as { [key: string]: number },
    )

  const getDate = ({ plannedDate }: MedicalOrderDto) =>
    plannedDate
      ? plannedDate
        .toString()
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
          '$3/$2/$1',
        )
      : ''
  const getHours = ({ plannedDate }: MedicalOrderDto) =>
    plannedDate
      ? plannedDate
        .toString()
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
          '$4:$5',
        )
      : ''
  const columns: ColumnType<MedicalOrderDto>[] = [
    {
      name: 'medicalOrderStatus',
      title: 'medicalOrderStatus',
      getCellValue: ({ status }) => status,
    },
    {
      name: 'firstName',
      title: 'firstName',
      getCellValue: ({ patient }) => (patient ? patient.firstName : ''),
    },
    {
      name: 'lastName',
      title: 'lastName',
      getCellValue: ({ patient }) => (patient ? patient.lastName : ''),
    },
    {
      name: 'sex',
      title: 'sex',
      getCellValue: ({ patient }) => patient.sex,
    },
    {
      name: 'age',
      title: 'age',
      getCellValue: getAge,
    },
    {
      name: 'date',
      title: 'date',
      getCellValue: getDate,
    },
    {
      name: 'time',
      title: 'time',
      getCellValue: getHours,
    },
    {
      name: 'site',
      title: 'site',
      getCellValue: ({ site }) => site.label,
    },
    {
      name: 'modality',
      title: 'modality',
      getCellValue: ({ exam }) =>
        intl.formatMessage({ id: 'enums.modality.' + exam.modality }),
    },
    {
      name: 'description',
      title: 'description',
      getCellValue: ({ exam }) => exam.label,
    },
    {
      name: 'imagesNumber',
      title: 'images',
    },
    {
      name: 'bodyPart',
      title: 'bodyPart',
      getCellValue: ({ exam }) =>
        intl.formatMessage({ id: 'enums.bodyPart.' + exam.bodyPart }),
    },
    {
      name: 'actions',
      title: 'actions',
      getCellValue: (row) => (
        <div className={classes.div}>
          <ReportTooltip onClick={() => openReportDialog(row)} study={row} />
          <ReportTooltipMemo study={row} />
          <ImageTooltip study={row} account={account} />
          <DownloadTooltip study={row} account={account} />
          <ShareTooltip
            onClick={() => onpenShareStudyDialog(row)}
            study={row}
            account={account}
          />
        </div>
      ),
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item container>
        <IntegrateTable
          role={AccountRoleEnum.doctor}
          columns={columns}
          rows={studies.datas}
          loading={loading}
          refresh={refresh}
          columnOrder={user ? user.preferences.display.home.columnOrder : []}
          setColumnOrder={setColumnOrder}
          hiddenColumns={
            user ? user.preferences.display.home.hiddenColumns : []
          }
          setHiddenColumns={setHiddenColumns}
          columnWidths={
            user
              ? untransformColumnWidth(
                user.preferences.display.home.columnWidth,
              )
              : []
          }
          setColumnWidths={setColumnWidths}
          // columnWidths={columWidth}
          // stringColumns={stringColumns}
          // dateColumns={dateColumns}
          // defaultcolumnWidths={defaultColumWidth}
          rightColumns={['actions']}
          headerIcon="fa fa-x-ray"
          headerTitle="my-patients"
          pagingState={{
            defaultCurrentPage: studies.size,
            defaultPageSize: studies.size,
            currentPage: studies.page,
            pageSize: studies.size,
            onCurrentPageChange: handleCurrentPageChange,
            onPageSizeChange: handlePageSizeChange,
            totalCount: studies.totalCount,
          }}
        />
      </Grid>
      {study && (
        <StudyDialog open={reportOpen} setOpen={setReportOpen} study={study} />
      )}
      {study && (
        <ShareStudyDialog
          open={shareOpen}
          setOpen={setShareOpen}
          study={study}
        />
      )}
    </Grid>
  )
}
