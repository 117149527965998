// import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

// const RegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  password: Yup.string().required('validation.password.required'),
  captcha: Yup.string().required('validation.field.required'),
  // confirmCaptcha: Yup.string()
  // .required('validation.captcha.required')
  // .oneOf([Yup.ref('captcha'), null], 'validation.captcha.failed'),
})

export const firstconnexionSchema = Yup.object().shape({
  medicalOrderId: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  captcha: Yup.string(),
})

export const firstconnexionDoctorSchema = Yup.object().shape({
  rpps: Yup.object().required('validation.field.required'),
  captcha: Yup.string().required('validation.field.required'),
})

export const forgottenPassowrdSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const registerGuestSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('validation.password.required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'validation.password.strength',
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'validation.password.match')
    .required('validation.password.required'),
})

export const updateEmailSchema = Yup.object().shape({
  email: Yup.string().lowercase().trim().email('validation.email.invalid'),
})

export const phoneCodeSchema = Yup.object().shape({
  code: Yup.string()
    .required('validation.field.required')
    .length(6)
    .matches(/^[0-9]{6}$/, 'validation.code.match'),
})

export const updateGeneralInfoSchema = Yup.object().shape({
  firstName: Yup.string().required('validation.field.required'),
  lastName: Yup.string().required('validation.field.required'),
  sex: Yup.string().required('validation.field.required'),
  street: Yup.string().required('validation.field.required'),
  postalCode: Yup.string().required('validation.field.required'),
  city: Yup.string().required('validation.field.required'),
  country: Yup.string().required('validation.field.required'),
  birthPlace: Yup.string().required('validation.field.required'),
  birthDate: Yup.date().required('validation.field.required'),
  ssn: Yup.string().required('validation.field.required'),
})

export const shareExamSchema = Yup.object().shape({
  email: Yup.string()
    .lowercase()
    .trim()
    .email('validation.email.invalid')
    .required('validation.email.required'),
})

export const shareStudySchema = Yup.object().shape({
  doctor: Yup.object().required('validation.field.required'),
})
