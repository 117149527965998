import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import {
  Dialog,
  DialogContent,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { DialogTitle } from '@components/titles'
import { Text } from '../texts'
import { Document, EnumGedFileType, MedicalOrderDto } from '@services/api'

import {
  deletePrescription,
  findOnePrescription,
  findOneReport,
  uploadPrescription,
} from '@state/thunks/studyThunk'
import { FileInput } from '@components/inputs'
import { cleanStudy } from '@state/reducers/studyReducer'

type ReportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}

export function StudyDialog({ open, setOpen }: ReportDialogProps) {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { study } = useSelector(({ study }) => ({
    study: study.selected as MedicalOrderDto,
  }))
  const documents = useMemo(() => study.documents, [study.documents])

  const handleDownload = (file: Document) => {
    const link = document.createElement('a')
    link.href = file.url
    link.download = file.gedFile.filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleUpload = (files: File[], type: EnumGedFileType) => {
    dispatch(uploadPrescription({ id: study.id, files, type }))
  }

  const handleDelete = (file: Document) => {
    dispatch(
      deletePrescription({
        id: file.medicalOrderId,
        prescriptionId: file.id,
      }),
    )
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      return
    }
    if (study.reports.length) {
      study.reports.forEach((report) => dispatch(findOneReport(report.fileId)))
    }
    if (documents && documents.length !== 0) {
      documents.forEach((doc) => {
        dispatch(findOnePrescription(doc.fileId))
      })
    }
    return () => {
      dispatch(cleanStudy())
    }
  }, [study.reports, documents.length, open])

  const { attendingDoctor, site } = study

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title={study.exam.label}
        style={{ background: '#a1c5c5', color: '#fff' }}
      />
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Text variant="h5" text="informations" format />
            <List>
              <ListItem>
                <ListItemIcon>
                  <Icon className="fa fa-user-md" />
                </ListItemIcon>
                <ListItemText
                  primary={intl.formatMessage({ id: 'referringDoctor' })}
                  secondary={
                    attendingDoctor
                      ? `${attendingDoctor?.firstName} ${attendingDoctor?.lastName}`
                      : intl.formatMessage({ id: 'noReferringDoctor' })
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black' },
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon className="fa fa-map-marker" />
                </ListItemIcon>
                <ListItemText
                  primary={`Site: ${site.label}`}
                  secondary={
                    study.site.streetAddress + ', ' + study.site.postalCode
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black' },
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon className="fa fa-phone" />
                </ListItemIcon>
                <ListItemText
                  primary="Contact"
                  secondary={
                    site && site.phoneNumber ? (
                      <a href={`tel:${site.phoneNumber}`}>{site.phoneNumber}</a>
                    ) : (
                      intl.formatMessage({ id: 'noContact' })
                    )
                  }
                  secondaryTypographyProps={{
                    style: { color: 'black' },
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Text variant="h5" text="document" format />
            </Grid>
            <Grid item xs={12} style={{ marginLeft: 25 }}>
              <FileInput
                accept="application/msword, application/pdf, image/*, application/jpeg, application/png, application/txt"
                files={documents}
                onChange={handleUpload}
                onDownloadFile={handleDownload}
                onDeleteFile={handleDelete}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
