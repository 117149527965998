import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'

import {
  Chip,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Tooltip,
} from '@material-ui/core'

import { Close, Email, Person } from '@material-ui/icons'
import { AddSharingForm } from '@components/forms'
import { DialogTitle } from '@components/titles'
import { ShareWithEmailForm } from '../forms/ShareWithEmailForm'

import { Text } from '../texts'
import {
  Shares,
  MedicalOrderDto,
  SharingExpirationEnum,
  AccountRoleEnum,
} from '@services/api'
import { unshare } from '@state/thunks/studyThunk'
import { useIntl } from 'react-intl'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  dialogTitle: {
    background: '#a1c5c5',
    color: '#fff',
  },
  dialogContent: {
    width: 500,
  },
  searchTitle: {
    marginTop: 10,
  },
  searchTips: {
    color: 'grey',
    fontSize: '12px',
  },
  sharingTitle: {
    marginBottom: 10,
  },
  table: {
    marginBottom: 10,
  },
  tableContent: {
    padding: 5,
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '550px',
  },
}))
type ReportDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  study: MedicalOrderDto
}

export function ShareStudyDialog({ open, setOpen, study }: ReportDialogProps) {
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)

  const { shares, account } = useSelector(({ study, auth }) => ({
    shares: study.selected?.shares as Shares[],
    account: auth.account,
    // loading: loading['study/unshare'] || loading['study/share'],
  }))
  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue)
  }

  const handleUnshare = (id: number) => {
    dispatch(unshare(id))
  }

  const onClose = () => setOpen(false)

  const getFormatedDate = (
    startDate: Date,
    expiration: SharingExpirationEnum,
  ) => {
    let expirationDate = new Date()
    switch (expiration) {
      case SharingExpirationEnum.WEEK_1:
        expirationDate = moment(startDate).add(1, 'week').toDate()
        break
      case SharingExpirationEnum.WEEK_2:
        expirationDate = moment(startDate).add(2, 'weeks').toDate()
        break
      case SharingExpirationEnum.WEEK_3:
        expirationDate = moment(startDate).add(3, 'weeks').toDate()
        break
      case SharingExpirationEnum.MONTH_1:
        expirationDate = moment(startDate).add(1, 'month').toDate()
        break
      case SharingExpirationEnum.MONTH_2:
        expirationDate = moment(startDate).add(2, 'months').toDate()
        break
      case SharingExpirationEnum.MONTH_6:
        expirationDate = moment(startDate).add(6, 'months').toDate()
        break
      case SharingExpirationEnum.NO_EXPIRATION:
      default:
        return 'Never'
    }
    if (expirationDate.getTime() < Date.now()) return 'Expired'
    else return moment(expirationDate).format('DD/MM/YYYY')
  }
  const capitalize = (name: string) =>
    name.charAt(0).toUpperCase() + name.slice(1)

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xl"
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle
        onClose={onClose}
        title="sharing"
        className={classes.dialogTitle}
        format
      />
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tooltip
            title="Share you medical order with a doctor registered in Ether Healthcare"
            arrow
          >
            <Tab
              icon={<Person />}
              label={intl.formatMessage({ id: 'shareWithPro' })}
            />
          </Tooltip>
          <Tooltip title="Share you medical order by email" arrow>
            <Tab
              icon={<Email />}
              label={intl.formatMessage({ id: 'shareWithEmail' })}
            />
          </Tooltip>
        </Tabs>
      </Paper>
      <DialogContent className={classes.dialogContent}>
        {value === 0 ? (
          <>
            <Text
              variant="h6"
              text="searchDoctor"
              className={classes.searchTitle}
              format
            />
            <Text text="doctorNotFound" className={classes.searchTips} format />
            <AddSharingForm medicalOrderId={study.id} />

            {account?.role === AccountRoleEnum.patient && (
              <>
                <Divider className={classes.divider} />

                <Text
                  variant="h6"
                  text="activeSharing"
                  className={classes.sharingTitle}
                  format
                />
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {intl.formatMessage({ id: 'recipient' })}
                        </TableCell>
                        <TableCell>
                          {intl.formatMessage({ id: 'permission' })}
                        </TableCell>
                        <TableCell align="right">
                          {intl.formatMessage({ id: 'expiration' })}
                        </TableCell>
                        {/* <TableCell>{intl.formatMessage({ id: 'Doctor' })}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shares.length === 0 && (
                        <TableRow className={classes.tableContent}>
                          <Text
                            text="noSharing"
                            format
                            className={classes.tableContent}
                          />
                        </TableRow>
                      )}
                      {shares.map((share, index) => {
                        const account: any = share.account
                        let title = ''
                        const user =
                          account.doctor || account.patient || account.guest
                        if (account.role === AccountRoleEnum.guest && !user) {
                          title = 'Not opened yet'
                        } else {
                          title =
                            capitalize(user.firstName) +
                            ' ' +
                            capitalize(user.lastName)
                        }
                        return (
                          <TableRow
                            key={index}
                            className={classes.tableContent}
                          >
                            <TableCell component="th" scope="row">
                              {account.doctor && 'Dr'} {title}
                            </TableCell>
                            <TableCell>
                              <Chip label={share.permission} />
                            </TableCell>
                            <TableCell align="right">
                              {getFormatedDate(
                                share.startDate,
                                share.expiration,
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => handleUnshare(share.id)}
                              >
                                <Close />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        ) : (
          <ShareWithEmailForm medicalOrderId={study.id} cb={setValue} />
        )}
      </DialogContent>
    </Dialog>
  )
}
