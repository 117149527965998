import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { CaptchaInput, DateInput, TextInput } from '@ether-healthcare/inputs'
import { Button } from '@components/buttons'

import { firstconnexionSchema } from '@utils/schemas'
import { AppDispatch } from '@state/store'

import { firstConnexion, getCaptcha } from '@state/thunks/authThunk'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useEffect } from 'react'

type Values = {
  medicalOrderId: string
  birthDate: Date
  captcha: string
}

export function FirstConnexionPatientForm() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { loading, captcha } = useSelector(({ loading, auth }) => ({
    loading: loading['auth/first-connexion'],
    captcha: auth.captcha,
  }))
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(firstconnexionSchema),
    defaultValues: {
      birthDate: new Date(),
    },
  })

  const onSubmit = (values: Values) => {
    //TODO check captcha first
    dispatch(
      firstConnexion({
        accessionNumber: values.medicalOrderId.trim(),
        birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
      }),
    ).then(({ payload }: any) => {
      if (payload.accessionNumber && payload.birthDate) {
        navigate(
          `/auth/register-patient?id=${payload.accessionNumber}&b=${payload.birthDate}`,
        )
      }
    })
  }
  const getAuthCaptcha = () => {
    dispatch(getCaptcha())
  }

  useEffect(() => {
    getAuthCaptcha()
  }, [])

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: 20 }}
    >
      <Grid item xs={12} sm={12}>
        <TextInput
          name="medicalOrderId"
          label="Number"
          variant="filled"
          errors={errors}
          control={control}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <DateInput
          name="birthDate"
          control={control}
          errors={errors}
          variant="filled"
        />
      </Grid>

      <Grid item container justifyContent="center" xs={12} sm={12}>
        <CaptchaInput
          getCaptcha={getAuthCaptcha}
          captcha={captcha}
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          color="primary"
          format
        />
      </Grid>
    </Grid>
  )
}
