import { memo } from 'react'

import { Delete, GetApp as Download } from '@material-ui/icons'
import { Button, Grid, Tooltip } from '@material-ui/core'

import { EtherFile } from '@services/extendedType'
import { Text } from '@components/texts'
import { Document } from '../../../services/api'

interface Props {
  index: number
  file: Document
  onDelete: (item: any) => void
  onDownload: (item: any) => void
  onChangeStatus?: (item: EtherFile) => void
}

const FileItem = ({ file, onDelete, onDownload }: Props) => {
  const handleDelete = () => {
    onDelete && onDelete(file)
  }
  const handleDownload = () => {
    onDownload && onDownload(file)
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={2}>
        <Tooltip title="Download">
          <Button onClick={handleDownload} size="small" color="primary">
            <Download fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button onClick={handleDelete} size="small" color="secondary">
            <Delete fontSize="small" />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={8}>
        <Text text={file.fileType} format />
        <Text
          text={file.gedFile.filename}
          style={{ fontSize: '12px', color: 'grey' }}
          format
        />
      </Grid>
    </Grid>
  )
}

export default memo(FileItem)
