import { Link } from 'react-router-dom'
import { Container, Divider, Grid, makeStyles } from '@material-ui/core'
import { Text } from '@components/texts'

const useStyles = makeStyles(() => ({
  footer: {
    height: '36px',
    padding: 10,
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <Container maxWidth="sm" component="footer" className="ec-footer">
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.footer}
      >
        <Link to="/privacy-policy">
          <Text text="privacy" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="copyright" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="about" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/terms">
          <Text text="terms" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="contact-us" align="center" format />
        </Link>
      </Grid>
    </Container>
  )
}
