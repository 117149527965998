/* eslint-disable no-case-declarations */
import {
  NotificationDto,
  NotificationTypeEnum,
  NewShareNotificationDto,
  NewResultAvailableNotification,
} from '../services/api'

export const getNotificationText = (n: NotificationDto) => {
  let text: string
  switch (n.type) {
    case NotificationTypeEnum.MEDICAL_ORDER_SHARED:
      const newShareNotificationDto = n as NewShareNotificationDto
      text = `Patient ${newShareNotificationDto.share.medicalOrder.patient.firstName} ${newShareNotificationDto.share.medicalOrder.patient.lastName} shared a new study with you.`
      break

    case NotificationTypeEnum.EXAM_RESULT_AVAILABLE:
      const newResultAvailableNotification = n as NewResultAvailableNotification
      text = `You have a new result for the ${newResultAvailableNotification.medicalOrder.exam.label}`
      break

    default:
      text = ''
  }

  return text
}
