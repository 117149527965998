import { TooltipButton } from '@components/buttons'
import { CircularProgress } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import {
  SharingPermissionEnum,
  AccountRoleEnum,
  AccountDto,
  MedicalOrderDto,
} from '@services/api'
import { AppDispatch } from '@state/store'
import { downloadImage } from '@state/thunks/studyThunk'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

type DownloadTooltipProps = {
  study: MedicalOrderDto
  account?: AccountDto
}
import { isSharingExpired } from '@utils/utils'

export function DownloadTooltip({ study, account }: DownloadTooltipProps) {
  const dispatch = useDispatch<AppDispatch>()
  const [loading, setLoading] = useState(false)

  const canDownloadImages = () => {
    if (account?.role === AccountRoleEnum.doctor && study.radiologyStudy) {
      const share = study.shares.find((share) => share.accountId === account.id)
      if (
        share &&
        (share.permission === SharingPermissionEnum.REPORT ||
          share.permission === SharingPermissionEnum.SHARE) &&
        !isSharingExpired(share.startDate, share.expiration)
      ) {
        return true
      }
    }
    return false
  }

  const handleImageDownload = () => {
    setLoading(true)
    dispatch(downloadImage(study.id))
      .then(({ payload: { url, filename } }) => {
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.createObjectURL(url)
        setLoading(false)
      })
      .catch(
        () => console.error('Time out'),
        // dispatch(enqueueSnackbar({ type: 'error', message: 'Time out !' })),
      )
  }

  return (
    <TooltipButton
      title="download"
      id="download-image"
      onClick={handleImageDownload}
      disable={account ? !canDownloadImages() : !study.radiologyStudy}
      color={study.radiologyStudy ? 'primary' : undefined}
      icon={loading ? <CircularProgress size={20} /> : <GetApp />}
    />
  )
}
