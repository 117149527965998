import { createRef, memo, useState } from 'react'

import { Add } from '@material-ui/icons'
import { Tooltip, Grid, Select, MenuItem } from '@material-ui/core'
import { Text } from '@components/texts'

import FileItem from './FileItem'
import { Document, EnumGedFileType } from '../../../services/api'

interface Props {
  accept?: string
  files: Document[]
  onChange: (files: File[], type: EnumGedFileType) => void
  onDeleteFile: (file: Document) => void
  onDownloadFile: (file: Document) => void
  multiple?: boolean
}

export const FileInput = ({
  accept,
  files,
  onChange,
  onDeleteFile,
  multiple,
  onDownloadFile,
}: Props) => {
  const inputRef = createRef<HTMLInputElement>()
  const [over, setover] = useState(false)
  const [selectedType, setSelectedType] = useState(EnumGedFileType.prescription)

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (event.target.files.length > 1) {
        alert('Max number of file is 1 !')
        return false
      }
      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index]
        if (file.size > 4194304) {
          alert('Max file size is 4Mo !')
          return false
        }
      }

      onChange(Array.from(event.target.files), selectedType)
    }
  }

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
      <ul className="input-files">
        {!multiple && files.length < 10 && (
          <Grid item container>
            <Grid item xs={12}>
              <Text
                variant="caption"
                text="addDocument"
                style={{ color: 'grey' }}
                format
              />
            </Grid>

            <Grid item container alignItems="center">
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <Text variant="body1" text="chooseType" format />
              </Grid>
              <Grid item xs={3} style={{ marginRight: '20px' }}>
                <Select
                  variant="outlined"
                  fullWidth
                  value={selectedType}
                  onChange={(event) =>
                    setSelectedType(event.target.value as EnumGedFileType)
                  }
                  name="fileType"
                  style={{
                    marginBottom: '10px',
                    height: '35px',
                  }}
                >
                  {Object.values(EnumGedFileType).map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={2}>
                <li
                  onDrop={(e: any) => {
                    e.preventDefault()
                    // e.persist()
                    e.target = e.dataTransfer
                    handleFilesChange(e)
                  }}
                  onDragOver={(e) => {
                    e.preventDefault()
                    setover(true)
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault()
                    setover(false)
                  }}
                  onClick={handleClick}
                  className={
                    over ? 'item-base item-input over' : 'item-base item-input'
                  }
                >
                  <Tooltip title="Add">
                    <Add />
                  </Tooltip>
                  <input
                    ref={inputRef}
                    type="file"
                    name="myFiles"
                    accept={accept}
                    multiple={multiple}
                    onChange={handleFilesChange}
                  />
                </li>
              </Grid>
            </Grid>
          </Grid>
        )}
        {files.map((file, i) => (
          <FileItem
            key={file.id}
            index={i}
            file={file}
            onDelete={onDeleteFile}
            onDownload={onDownloadFile}
          />
        ))}
      </ul>
    </Grid>
  )
}

export const MemoizedFileInput = memo(FileInput)
