import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Avatar,
  Badge,
  Hidden,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import {
  AccountCircle,
  ExitToApp,
  MoreVert,
  Notifications,
} from '@material-ui/icons'

import { logout } from '@state/thunks/authThunk'

import { FormattedMessage } from 'react-intl'
import { Text } from '@components/texts'
import theme from '@utils/theme'
import { getNotificationText } from '../utils/helpers'
import { readNotifications } from '../state/thunks/notificationThunk'

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  userName: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: '#475757',
    textTransform: 'capitalize',
    marginRight: '5px',
  },
  menuButton: {
    display: 'flex',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionDesktop: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar: {
    backgroundColor: 'transparent',
  },
  icon: {
    marginRight: '1.5rem',
    width: '16px',
  },
}))

export default function NavBar() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const { user } = useSelector(({ auth }) => ({
    user: auth.user,
  }))
  const notifications = useSelector(
    ({ notification }) => notification.notifications.datas,
  )

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget)
    dispatch(readNotifications({ ids: notifications.map((notif) => notif.id) }))
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }
  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null)
  }
  const handleLogout = () => {
    dispatch(logout())
    //TODO: Clear all
  }

  const handleProfile = () => navigate('/profile')

  const unreadLength = useMemo(() => {
    let count = 0
    notifications.forEach((_notif) => {
      if (!_notif.isRead) {
        count++
      }
    })
    return count
  }, [notifications])

  const menuId = 'account-menu'
  const renderAccountMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleProfile(), handleMenuClose()
        }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="account" />} />
      </MenuItem>
      <MenuItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="logout" />} />
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      //id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfile}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem button onClick={handleLogout}>
        <IconButton>
          <ExitToApp />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )

  const notificationMenuId = 'notification-menu'

  const renderNotificationMenu = (
    <Menu
      id={notificationMenuId}
      anchorEl={notificationAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(notificationAnchorEl)}
      onClose={handleNotificationMenuClose}
    >
      {notifications.map((_n, index) => (
        <MenuItem key={index}>
          <Text
            key={index}
            variant="caption"
            text={getNotificationText(_n)}
            style={{
              color:
                _n.isRead === false ? theme.palette.secondary.main : 'grey',
            }}
          />
        </MenuItem>
      ))}
    </Menu>
  )

  return (
    <Hidden xsDown>
      <AppBar position="static" className={classes.appbar} elevation={2}>
        <Toolbar
          style={{ borderBottom: '1px solid grey', background: '#a1c5c5' }}
        >
          <div
            onClick={() => navigate('/')}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            <Icon
              className={`fa fa-cloud ${classes.icon}`}
              style={{ color: '#3f3f3f' }}
            />
            <Text
              variant={'h6'}
              text="ETHER HEALTHCARE"
              style={{ color: '#3f3f3f', letterSpacing: '-1px' }}
            />
          </div>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {user && (
              <>
                <div className={classes.userName}>
                  {`${user.firstName} `}
                  {user.lastName}
                </div>
                <Tooltip title="notification(s)">
                  <span>
                    <IconButton
                      aria-label="notifications"
                      aria-controls={notificationMenuId}
                      onClick={handleNotificationMenuOpen}
                      disabled={!notifications.length}
                    >
                      <Badge badgeContent={unreadLength} color="secondary">
                        <Notifications />
                      </Badge>
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    user.firstName.toUpperCase() +
                    ' ' +
                    user.lastName.toUpperCase()
                  }
                >
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                  >
                    <Avatar
                      style={{
                        color: '#fff',
                        background: '#475757',
                        textTransform: 'capitalize',
                      }}
                    >
                      {/* {user.firstName[0]}
                      {user.lastName[0]} */}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </div>
        </Toolbar>
        {renderMobileMenu}
        {renderNotificationMenu}
        {renderAccountMenu}
      </AppBar>
    </Hidden>
  )
}
