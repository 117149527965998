import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui'
import { TableCell } from '@material-ui/core'
import StudyStatusFilter from './StudyStatusFilter'
import SexFilter from './SexFilter'
import ExamTypeFilter from './ExamTypeFilter'
import ModalityFilter from './ModalityFilter'

/**
 * Change Filter Cell conponent.
 * Used by `cellComponent` in `TableFilterRow`
 * @param column
 * @returns
 */
export const FilterCell = ({ column, ...props }: any) => {
  switch (column.name) {
    case 'medicalOrderStatus':
      return <StudyStatusFilter {...props} />
    case 'modality':
      return <ModalityFilter {...props} />
    case 'sex':
      return <SexFilter {...props} />
    case 'examType':
      return <ExamTypeFilter {...props} />
    case 'color':
      return <TableCell style={{ backgroundColor: 'white' }} />
    default:
      return <TableFilterRow.Cell {...props} />
  }
}
