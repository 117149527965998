import {
  Action,
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { TableRowDetail } from '@devexpress/dx-react-grid'

export const DetailEditCell = () => (
  <Plugin name="detailEdit">
    <Action
      name="toggleDetailRowExpanded"
      action={(
        { rowId },
        { expandedDetailRowIds },
        { startEditRows, stopEditRows },
      ) => {
        const rowIds = [rowId]
        const isCollapsing = expandedDetailRowIds.indexOf(rowId) > -1
        if (isCollapsing) {
          stopEditRows({ rowIds })
        } else {
          startEditRows({ rowIds })
        }
      }}
    />
    <Template
      name="tableCell"
      predicate={({ tableRow }: any) =>
        tableRow.type === TableRowDetail.ROW_TYPE
      }
    >
      {(params: any) => (
        <TemplateConnector>
          {(
            {
              tableColumns,
              // createRowChange,
              rowChanges,
            },
            {
              // changeRow,
              // commitChangedRows,
              cancelChangedRows,
              toggleDetailRowExpanded,
            },
          ) => {
            if (tableColumns.indexOf(params.tableColumn) !== 0) {
              return null
            }
            const {
              tableRow: { rowId },
            } = params
            const row = { ...params.tableRow.row, ...rowChanges[rowId] }

            const onClose = () => {
              toggleDetailRowExpanded({ rowId })
              cancelChangedRows({ rowIds: [rowId] })
            }

            return (
              <TemplatePlaceholder
                params={{
                  ...params,
                  row,
                  //   tableRow: {
                  //     ...params.tableRow,
                  //     row,
                  //   },
                  //   changeRow,
                  //   processValueChange,
                  //   applyChanges,
                  //   cancelChanges,
                  onClose,
                }}
              />
            )
          }}
        </TemplateConnector>
      )}
    </Template>
  </Plugin>
)
