import { SharingExpirationEnum } from '@services/api'
import moment from 'moment'

export const isSharingExpired = (
  startDate: Date,
  expiration: SharingExpirationEnum,
) => {
  let expirationDate = new Date()
  switch (expiration) {
    case SharingExpirationEnum.WEEK_1:
      expirationDate = moment(startDate).add(1, 'week').toDate()
      break
    case SharingExpirationEnum.WEEK_2:
      expirationDate = moment(startDate).add(2, 'weeks').toDate()
      break
    case SharingExpirationEnum.WEEK_3:
      expirationDate = moment(startDate).add(3, 'weeks').toDate()
      break
    case SharingExpirationEnum.MONTH_1:
      expirationDate = moment(startDate).add(1, 'month').toDate()
      break
    case SharingExpirationEnum.MONTH_2:
      expirationDate = moment(startDate).add(2, 'months').toDate()
      break
    case SharingExpirationEnum.MONTH_6:
      expirationDate = moment(startDate).add(6, 'months').toDate()
      break
    case SharingExpirationEnum.NO_EXPIRATION:
    default:
      return false
  }
  if (expirationDate.getTime() < Date.now()) return true
  else return false
}
