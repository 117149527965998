import { useSelector } from '@state/store'
import { IntlProvider } from 'react-intl'

import { SnackbarProvider } from 'notistack'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { locales } from './locales'
import Notifier from './containers/Notifier'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import Profile from './containers/app/Profile'
import FirstConnexion from './containers/auth/FirstConnexion'
import ForgottenPassword from './containers/auth/ForgottenPassword'
import Login from './containers/auth/Login'
import RegisterDoctor from './containers/auth/RegisterDoctor'
import RegisterPatient from './containers/auth/RegisterPatient'
import ResetPassword from './containers/auth/ResetPassword'
import VerificationEmail from './containers/auth/VerificationEmail'
import Home from './containers/app/Home'
import NotFound from './containers/auth/NotFound'
import AppLayout from './layouts/appLayout'
import AuthLayout from './layouts/authLayout'
import RegisterGuest from './containers/auth/RegisterGuest'

export default function App() {
  const { language } = useSelector(({ auth }) => ({
    language: auth.language,
  }))
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <IntlProvider locale={language} messages={locales(language)}>
        <SnackbarProvider>
          <Notifier />
          <Router>
            <Routes >
              <Route path="auth" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="first-connexion" element={<FirstConnexion />} />
                <Route
                  path="forgotten-password"
                  element={<ForgottenPassword />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="register-doctor" element={<RegisterDoctor />} />
                <Route path="register-patient" element={<RegisterPatient />} />
                <Route
                  path="verification-mail"
                  element={<VerificationEmail />}
                />
                <Route path="register-guest" element={<RegisterGuest />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  )
}
