import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import { Row } from '@devexpress/dx-react-grid'
import {
  ButtonGroup,
  Dialog,
  DialogContent,
  Hidden,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import { Add, Refresh } from '@material-ui/icons'
import { Button } from '@components/buttons'
import { DialogTitle } from '@components/titles'
import { useIntl } from 'react-intl'

const pluginDependencies = [{ name: 'Toolbar' }]

type ToolbarOptionsProps = {
  dialogTitle?: string
  formDialog?: any
  group: string[]
  columnGroup: string[]
  onColumnGroupChange?: (columnGroup: string[]) => void
  refresh?: () => void
  loading?: boolean
}

const useStyles = makeStyles(() => ({
  buttonSelected: {
    backgroundColor: 'grey',
    color: 'white',
  },
  buttonUnselected: {
    color: 'black',
  },
  buttonGroup: {
    marginLeft: 20,
  },
}))

export function ToolbarOptions({
  dialogTitle,
  formDialog: DialogForm,
  group,
  columnGroup,
  onColumnGroupChange,
  refresh,
}: ToolbarOptionsProps) {
  const classes = useStyles()
  const handleFormat = (newGrouping: string) => {
    if (onColumnGroupChange) {
      console.log(
        'Group',
        group,
        'columnGroup',
        columnGroup,
        'newGrouping',
        newGrouping,
      )

      if (columnGroup.includes(newGrouping)) {
        onColumnGroupChange(
          columnGroup.filter((grouping) => grouping !== newGrouping),
        )
      } else onColumnGroupChange([...columnGroup, newGrouping])
    }
  }
  const intl = useIntl()
  
  return (
    <Plugin name="ToolbarOptions" dependencies={pluginDependencies}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />

        {columnGroup && (
          <TemplateConnector>
            {() => (
              <ButtonGroup
                variant="text"
                color="primary"
                aria-label="contained primary button group"
                className={classes.buttonGroup}
              >
                {group.map((item, index) => (
                  <Button
                    key={index}
                    text={item}
                    className={
                      columnGroup.includes(item)
                        ? classes.buttonSelected
                        : classes.buttonUnselected
                    }
                    onClick={() => handleFormat(item)}
                  />
                ))}
              </ButtonGroup>
            )}
          </TemplateConnector>
        )}
        {dialogTitle && (
          <TemplateConnector>
            {(_, { addRow }) => (
              <>
                <Hidden smDown>
                  <Button
                    onClick={() => addRow()}
                    format
                    text={dialogTitle}
                    color="primary"
                    endIcon={<Add />}
                  />
                </Hidden>
                <Hidden mdUp>
                  <IconButton onClick={() => addRow()}>
                    <Add />
                  </IconButton>
                </Hidden>
              </>
            )}
          </TemplateConnector>
        )}
        {refresh && (
          <TemplateConnector>
            {() => (
              <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
                <IconButton onClick={() => refresh()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </TemplateConnector>
        )}
      </Template>
      <Template name="popupEditing">
        {!!(DialogForm && dialogTitle) && (
          <TemplateConnector>
            {(
              {
                rows,
                getRowId,
                addedRows,
                editingRowIds,
                // createRowChange,
                rowChanges,
              },
              {
                // changeRow,
                // changeAddedRow,
                // commitChangedRows,
                // commitAddedRows,
                stopEditRows,
                cancelAddedRows,
                cancelChangedRows,
              },
            ): any => {
              const isNew = addedRows && addedRows.length > 0
              let editedRow: Row
              let rowId: number
              if (isNew) {
                rowId = 0
                editedRow = addedRows[rowId]
              } else {
                [rowId] = editingRowIds
                const targetRow = rows.filter(
                  (row: any) => getRowId(row) === rowId,
                )[0]
                editedRow = { ...targetRow, ...rowChanges[rowId] }
              }

              const rowIds = isNew ? [0] : editingRowIds

              const cancelChanges = () => {
                if (isNew) {
                  cancelAddedRows({ rowIds })
                } else {
                  stopEditRows({ rowIds })
                  cancelChangedRows({ rowIds })
                }
              }

              const open: boolean = isNew
              return (
                <Dialog
                  open={open}
                  onClose={cancelChanges}
                  maxWidth="lg"
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle
                    title={dialogTitle}
                    onClose={cancelChanges}
                    format
                  />
                  <DialogContent>
                    <DialogForm onClose={cancelChanges} row={editedRow} />
                  </DialogContent>
                </Dialog>
              )
            }}
          </TemplateConnector>
        )}
      </Template>
      <Template name="root">
        <TemplatePlaceholder />
        <TemplatePlaceholder name="popupEditing" />
      </Template>
    </Plugin>
  )
}
