import { Filter, Sorting } from '@devexpress/dx-react-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Document, MedicalOrderDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import {
  deletePrescription,
  findAll,
  findOne,
  findOnePrescription,
  findOneReport,
  share,
  shareWithGuest,
  unshare,
  uploadPrescription,
} from '@state/thunks/studyThunk'

type File = {
  filename: string
  url: string
}

export interface StudyPagination {
  size: number
  page: number
  totalCount: number
}

type studyState = {
  studies: {
    datas: MedicalOrderDto[]
  } & StudyPagination
  selected?: MedicalOrderDto
  filters: Filter[]
  sorting: Sorting[]
  documents: []
  reports: File[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  columnOrder: string[]
}

const initialState: studyState = {
  studies: {
    totalCount: 0,
    page: 0,
    datas: [],
    size: 15,
  },
  filters: [],
  sorting: [{ columnName: 'plannedDate', direction: 'asc' }],
  currentPage: 0,
  pageSize: 10,
  documents: [],
  selection: [],
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringDoctor',
    'open',
    'actions',
  ],
  reports: [],
}

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    removeStudies: (state) => {
      state.studies = initialState.studies
    },
    setSelected: (state, action: PayloadAction<MedicalOrderDto>) => {
      state.selected = action.payload
    },
    cleanStudy: (state) => {
      // if (state.prescription) {
      //   URL.revokeObjectURL(state.prescription.url)
      //   state.prescription = undefined
      // }
      state.reports.forEach((report) => URL.revokeObjectURL(report.url))
      state.reports = []
    },
    cleanReport: (state) => {
      state.reports.forEach((report) => URL.revokeObjectURL(report.url))
      state.reports = []
    },
    setStudyPagination: (
      state,
      { payload }: PayloadAction<Partial<StudyPagination>>,
    ) => {
      state.studies = { ...state.studies, ...payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        findAll.fulfilled,
        (state, { payload }: PayloadAction<PaginatedDto<MedicalOrderDto>>) => {
          state.studies.datas = payload.datas
          state.studies.totalCount = payload.totalCount
        },
      )
      .addCase(
        findOne.fulfilled,
        (state, { payload }: PayloadAction<MedicalOrderDto>) => {
          state.selected = payload
        },
      )
      .addCase(share.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if (state.selected)
          state.selected.shares = [...state.selected.shares, payload]
      })
      .addCase(
        shareWithGuest.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if (state.selected)
            state.selected.shares = [...state.selected.shares, payload]
        },
      )
      .addCase(unshare.fulfilled, (state, { payload }: PayloadAction<any>) => {
        console.log(payload)
        if (state.selected)
          state.selected.shares = state.selected.shares.filter(
            (s) => s.id !== payload,
          )
      })
      .addCase(
        uploadPrescription.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          if (payload.length === 0) {
            return
          }
          const medicalOrderId = payload[0].medicalOrderId

          const index = state.studies.datas.findIndex(
            (s) => s.id === medicalOrderId,
          )

          state.studies.datas[index].documents = payload

          if (state.selected) {
            state.selected.documents = payload
          }
        },
      )
      .addCase(
        findOneReport.fulfilled,
        (state, { payload }: PayloadAction<File>) => {
          state.reports.push(payload)
        },
      )
      .addCase(
        findOnePrescription.fulfilled,
        (state, { payload }: PayloadAction<File & { id: number }>) => {
          for (let i = 0; i < state.studies.datas.length; i++) {
            const study = state.studies.datas[i]
            const docIndex = study.documents.findIndex(
              (d) => d.fileId === payload.id,
            )
            if (docIndex !== -1) {
              state.studies.datas[i].documents[docIndex].url = payload.url
              break
            }
          }

          const { selected } = state
          if (selected) {
            const index = selected.documents.findIndex(
              (doc) => doc.fileId === payload.id,
            )
            if (index !== -1) {
              selected.documents[index].url = payload.url
            }
            state.selected = selected
          }
        },
      )
      .addCase(
        deletePrescription.fulfilled,
        (state, { payload }: PayloadAction<Document>) => {
          const index = state.studies.datas.findIndex(
            (s) => s.id === payload.medicalOrderId,
          )
          if (index === -1) {
            return
          }
          const docs = state.studies.datas[index].documents.filter(
            (d) => d.id !== payload.id,
          )

          state.studies.datas[index].documents = docs

          if (state.selected) {
            state.selected.documents = docs
          }
        },
      )
  },
})

export const {
  removeStudies,
  setSelected,
  cleanStudy,
  cleanReport,
  setStudyPagination,
} = studySlice.actions

export default studySlice.reducer
