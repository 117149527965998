import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch, useSelector } from '@state/store'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { ShareStudyDialog, StudyDialog } from '@components/dialogs'
import { IntegrateTable } from '@components/tables'
import { Patient, MedicalOrderDto, AccountRoleEnum } from '@services/api'

import {
  removeStudies,
  setSelected,
  setStudyPagination,
} from '@state/reducers/studyReducer'
import { ColumnType } from '@services/extendedType'
import { findAll } from '@state/thunks/studyThunk'
import { useIntl } from 'react-intl'
import { updatePatient } from '@state/thunks/authThunk'
import { throttle } from 'lodash'
import {
  updateColumnOrder,
  updateColumnWidths,
  updateHiddenColumns,
} from '@state/reducers/authReducer'
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid'
import {
  ReportTooltip,
  ImageTooltip,
  DownloadTooltip,
  ShareTooltip,
  ReportTooltipMemo,
} from '@components/tooltips'
import { findNotifications } from '../../../state/thunks/notificationThunk'
// import { enqueueSnackbar } from '@state/reducers/alertReducer'

const useStyles = makeStyles((theme) => ({
  div: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: 250,
  },
  iconBtn: {
    padding: 0,
  },
  icon: {
    marginRight: '1.5rem',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
}))

export default function HomePatient() {
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const [shareOpen, setShareOpen] = useState(false)
  const [reportOpen, setReportOpen] = useState(false)
  const notificationsLimit = useRef(10).current

  const { studies, study, loading, user, account } = useSelector(
    ({ study, loading, auth }) => ({
      user: auth.user as Patient | undefined,
      account: auth.account,
      studies: study.studies,
      study: study.selected,
      loading: loading['study/findAll'],
      // loadingReport: loading['report/download'],
      consent: false,
    }),
  )

  const refresh = () => {
    const limit = studies.size
    const skip = limit * studies.page
    dispatch(
      findAll({
        limit,
        skip,
      }),
    )
  }

  useEffect(() => {
    const limit = studies.size
    const skip = limit * studies.page
    dispatch(
      findAll({
        limit,
        skip,
      }),
    )
  }, [studies.page, studies.size])

  useEffect(() => {
    dispatch(findNotifications({ limit: notificationsLimit, skip: 0 }))
    return () => {
      dispatch(removeStudies())
    }
  }, [dispatch])

  const openReportDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setReportOpen(true)
  }

  const onpenShareStudyDialog = (study: MedicalOrderDto) => {
    dispatch(setSelected(study))
    setShareOpen(true)
  }

  const handleCurrentPageChange = (page: number) => {
    dispatch(
      setStudyPagination({
        page,
      }),
    )
  }

  const handlePageSizeChange = (size: number) => {
    dispatch(
      setStudyPagination({
        size,
      }),
    )
  }

  const setHiddenColumns = (hiddenColumns: string[]) => {
    if (user) dispatch(updateHiddenColumns(hiddenColumns))
  }
  const setColumnOrder = (columnOrder: string[]) => {
    if (user) dispatch(updateColumnOrder(columnOrder))
  }
  const setColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
    if (user) dispatch(updateColumnWidths(transformColumnWidth(columnWidths)))
  }

  const throttled = useCallback(
    throttle(() => {
      if (user)
        dispatch(
          updatePatient({
            id: user.id,
            body: {
              preferences: { display: { home: user.preferences.display.home } },
            },
          }),
        )
    }, 1000),
    [dispatch, user],
  )

  useEffect(() => {
    if (user && user.preferences) throttled()
  }, [user])

  // useEffect(() => {
  //   // if (study.prescription) {
  //   //   dispatch(findOnePrescription(study.prescription.fileId))
  //   // }
  //   return () => {
  //     dispatch(cleanStudy())
  //   }
  // }, [study?.reports, study?.prescription])

  const untransformColumnWidth = (columnWidth: { [key: string]: number }) =>
    Object.entries(columnWidth).map(([columnName, width]) => ({
      columnName,
      width,
    })) as TableColumnWidthInfo[]

  const transformColumnWidth = (columnWidth: TableColumnWidthInfo[]) =>
    columnWidth.reduce(
      (acc, column) => ({ ...acc, [column.columnName]: +column.width }),
      {} as { [key: string]: number },
    )

  const getAttendingDoctor = (row: MedicalOrderDto) =>
    row.attendingDoctor ? (
      <Box>
        {/* <Box color="#a0a3b1">{row.attendingDoctor.rppsNumber}</Box> */}
        <Box style={{ textTransform: 'capitalize' }}>
          {row.attendingDoctor.firstName} {row.attendingDoctor.lastName}
        </Box>
      </Box>
    ) : (
      ''
    )

  const columns: ColumnType<MedicalOrderDto>[] = [
    {
      name: 'attendingDoctor',
      title: 'attendingDoctor',
      getCellValue: getAttendingDoctor,
    },
    {
      name: 'medicalOrderStatus',
      title: 'medicalOrderStatus',
      getCellValue: (row) => row.status,
    },
    {
      name: 'date',
      title: 'date',
      getCellValue: (row) => row.plannedDate,
    },
    {
      name: 'time',
      title: 'time',
      getCellValue: (row) => row.plannedDate,
    },
    {
      name: 'examType',
      title: 'examType',
      getCellValue: (study) =>
        intl.formatMessage({ id: 'enums.modality.' + study.exam.modality }),
    },
    {
      name: 'description',
      title: 'description',
      getCellValue: (study) => study.exam.label,
    },

    {
      name: 'actions',
      title: 'actions',
      getCellValue: (row) => (
        <div className={classes.div}>
          <ReportTooltip onClick={() => openReportDialog(row)} study={row} />
          <ReportTooltipMemo study={row} />
          <ImageTooltip study={row} />
          <DownloadTooltip study={row} />
          {account?.role === AccountRoleEnum.patient && (
            <ShareTooltip
              onClick={() => onpenShareStudyDialog(row)}
              study={row}
              account={account}
            />
          )}
        </div>
      ),
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item container>
        <IntegrateTable
          role={AccountRoleEnum.patient}
          columns={columns}
          rows={studies.datas}
          loading={loading}
          refresh={refresh}
          columnOrder={
            user && user.preferences
              ? user.preferences.display.home.columnOrder
              : []
          }
          setColumnOrder={setColumnOrder}
          hiddenColumns={
            user && user.preferences
              ? user.preferences.display.home.hiddenColumns
              : []
          }
          setHiddenColumns={setHiddenColumns}
          columnWidths={
            user && user.preferences
              ? untransformColumnWidth(
                user.preferences.display.home.columnWidth,
              )
              : []
          }
          setColumnWidths={setColumnWidths}
          // stringColumns={stringColumns}
          // dateColumns={dateColumns}
          // onRowSelected={(row) => openReportDialog(row, 'doc')}
          rightColumns={['actions']}
          headerIcon="fa fa-x-ray"
          headerTitle="my-studies"
          pagingState={{
            defaultCurrentPage: studies.size,
            defaultPageSize: studies.size,
            currentPage: studies.page,
            pageSize: studies.size,
            onCurrentPageChange: handleCurrentPageChange,
            onPageSizeChange: handlePageSizeChange,
            totalCount: studies.totalCount,
          }}
        />
      </Grid>
      {/* <Grid item container alignItems="center">
        <Icon className={`fa fa-vials ${classes.icon}`} />
        <Text variant="h4" text="laboratory-results" format />
      </Grid> */}
      {study && (
        <StudyDialog open={reportOpen} setOpen={setReportOpen} study={study} />
      )}
      {study && (
        <ShareStudyDialog
          open={shareOpen}
          setOpen={setShareOpen}
          study={study}
        />
      )}
    </Grid>
  )
}
