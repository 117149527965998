import { useSelector } from '@state/store'
import { Container, Grid } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import {
  LanguageForm,
  UpdateEmailForm,
  UpdatePasswordForm,
  UpdatePhoneForm,
} from '@components/forms'

import { UserInfosCard } from '@components/cards'
import { AccountDto } from '@services/api'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: 'white',
  },
  section: {
    flexGrow: 1,
  },
}))
export default function Profile() {
  const classes = useStyles()
  const { language, user, account } = useSelector(({ auth }) => ({
    language: auth.language,
    account: auth.account as AccountDto,
    user: auth.user,
  }))

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid container item>
          <LanguageForm language={language} />
        </Grid>
        <Grid container item>
          {user && <UserInfosCard user={user} account={account} />}
        </Grid>
        <Grid container item>
          <UpdatePasswordForm account={account} />
        </Grid>
        <Grid container item>
          <UpdateEmailForm account={account} />
        </Grid>
        <Grid container item>
          <UpdatePhoneForm account={account} />
        </Grid>
        <Grid container item>
          {/* TODO integration needed <AccountForm userAccount={userAccount} /> */}
        </Grid>
      </Grid>
    </Container>
  )
}
