import { useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'
import { Button } from '@components/buttons'
import { CheckBoxInput, SelectInput, TextInput } from '@ether-healthcare/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { shareExamSchema } from '@utils/schemas'
import {
  SharingExpirationEnum,
  SharingPermissionEnum,
  AccountRoleEnum,
} from '@services/api'
import { shareWithGuest } from '@state/thunks/studyThunk'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { RppsAutocompleteByNameInput } from '@components/inputs/RppsAutocompleteByNameInput'
import { Text } from '../texts'
import { useState } from 'react'
import { AppDispatch, useSelector } from '@state/store'

type Values = {
  firstName: string
  lastName: string
  email: string
  doctor: any
  expiration: SharingExpirationEnum
  permission: SharingPermissionEnum
}

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
  searchTitle: {
    marginTop: -10,
    marginBottom: -15,
  },
  searchTips: {
    color: 'grey',
    fontSize: '12px',
    marginBottom: -15,
  },
}))

type AddSharingFormProps = {
  medicalOrderId: number
  cb: (tab: number) => void
}

export function ShareWithEmailForm({
  medicalOrderId,
  cb,
}: AddSharingFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const intl = useIntl()

  const [doctorNotFound, setDoctorNotFound] = useState(false)

  const { loading, account } = useSelector(({ loading, auth }) => ({
    loading: loading['study/share'],
    account: auth.account,
  }))

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(shareExamSchema) as any,
    defaultValues: {
      email: '',
      expiration: SharingExpirationEnum.WEEK_1,
      permission: SharingPermissionEnum.VIEW,
    },
  })

  const onSubmit = (values: Values) => {
    dispatch(
      shareWithGuest({
        rppsNumber: values.doctor ? values.doctor.rppsNumber : undefined,
        medicalOrderId,
        email: values.email,
        expiration: values.expiration,
        permission: values.permission,
      }),
    ).then(() => cb(0))
  }

  const studySharingPermissions = Object.keys(SharingPermissionEnum)
    .filter((permission) => {
      if (
        account?.role === AccountRoleEnum.doctor &&
        permission == SharingPermissionEnum.OWNER
      )
        return false
      else {
        return true
      }
    })
    .map((key) => ({
      value: SharingPermissionEnum[key],
      label: intl.formatMessage({
        id: 'enums.sharingPermission.' + SharingPermissionEnum[key],
      }),
    }))
  const studySharingExpirations = Object.keys(SharingExpirationEnum).map(
    (key) => ({
      value: SharingExpirationEnum[key],
      label: intl.formatMessage({
        id: 'enums.sharingExpiration.' + SharingExpirationEnum[key],
      }),
    }),
  )
  const handleChange = () => {
    setDoctorNotFound(!doctorNotFound)
  }

  const doctorSelected = watch('doctor')
  console.log(doctorSelected)
  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <Text
          variant="h6"
          text="searchDoctorFromRpps"
          className={classes.searchTitle}
          format
        />
      </Grid>
      <Grid item xs={12}>
        <Text text="searchTips" className={classes.searchTips} format />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <RppsAutocompleteByNameInput
          control={control}
          errors={errors}
          name="doctor"
        />
      </Grid>
      {(doctorSelected || doctorNotFound) && (
        <>
          <Grid item xs={12}>
            <TextInput
              name="email"
              variant={'outlined'}
              size="small"
              control={control}
              errors={errors}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <CheckBoxInput
          label={intl.formatMessage({ id: 'cantFindDoctor' })}
          name={intl.formatMessage({ id: 'cantFindDoctor' })}
          control={control}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          options={studySharingPermissions}
          control={control}
          name="permission"
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          options={studySharingExpirations}
          control={control}
          name="expiration"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          text="submit"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          color="primary"
          format
        />
      </Grid>
    </Grid>
  )
}
