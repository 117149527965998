import { TooltipButton } from '@components/buttons'
import { MedicalOrderDto } from '@services/api'
import { Icon } from '@material-ui/core'

type ReportTooltipProps = {
  study: MedicalOrderDto
  onClick: () => void
}

export function ReportTooltip({ study, onClick }: ReportTooltipProps) {
  return (
    <TooltipButton
      title="document"
      disable={study.status == 'canceled'}
      onClick={onClick}
      icon={
        <Icon
          fontSize="small"
          className="fa fa-file-medical"
          color={study.status == 'canceled' ? 'disabled' : 'primary'}
        />
      }
    />
  )
}
