import { Chip, Icon, makeStyles, Tooltip } from '@material-ui/core'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { red } from '@material-ui/core/colors'
import { FormattedMessage } from 'react-intl'

import { MedicalOrderStatusEnum } from '@services/api'

type ValueFormatterProps = DataTypeProvider.ValueFormatterProps

const useStyles = makeStyles({
  booleanFormatterGreen: {
    backgroundColor: 'green[500]',
    color: 'white',
  },
  booleanFormatterRed: {
    backgroundColor: red[500],
    color: 'white',
  },
  colorFormatter: {
    width: 70,
  },
})

const DateFormatter = ({ value }: ValueFormatterProps) => {
  return value
    ? value.replace(
      /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
      '$3/$2/$1',
    )
    : ''
}
const TimeFormatter = ({ value }: ValueFormatterProps) => {
  return value
    ? value.replace(
      /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
      '$4:$5',
    )
    : ''
}
const DateTimeFormatter = ({ value }: ValueFormatterProps) => {
  return value.replace(
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z/,
    '$3/$2/$1 - $4:$5:$6',
  )
}

const StatusFormatter = ({ value }: ValueFormatterProps) => (
  <>
    <Tooltip title={<FormattedMessage id={value} />}>
      {value === MedicalOrderStatusEnum.scheduled ? (
        <Icon className={'fa fa-calendar-day'} style={{ color: '#000000' }} />
      ) : value === MedicalOrderStatusEnum.canceled ||
        value === MedicalOrderStatusEnum.error ? (
          <Icon className={'fa fa-calendar-times'} style={{ color: '#E91E63' }} />
        ) : value === MedicalOrderStatusEnum.completed ||
        value === MedicalOrderStatusEnum.available ? (
            <Icon className={'fa fa-calendar-check'} style={{ color: '#009DA0' }} />
          ) : (
            <></>
          )}
    </Tooltip>
  </>
)

const sexFormatter = ({ value }: ValueFormatterProps) => (
  <Icon
    className={
      value === 'M'
        ? 'fa fa-mars'
        : value === 'F'
          ? 'fa fa-venus'
          : 'fa fa-question'
    }
    style={{ color: value === 'M' ? '#4195f5' : value === 'F' ? 'pink' : 'black' }}
  />
)

const booleanFormatter = ({ value }: ValueFormatterProps) => {
  const classes = useStyles()

  return (
    <Chip
      label={value ? 'True' : 'False'}
      className={
        value ? classes.booleanFormatterGreen : classes.booleanFormatterRed
      }
    />
  )
}
const colorFormatter = ({ value }: ValueFormatterProps) => {
  const classes = useStyles()

  return (
    <Chip
      style={{ backgroundColor: value }}
      className={classes.colorFormatter}
    />
  )
}

type ColumnProviderType = {
  types: string[]
  formatterComponent: React.ComponentType<ValueFormatterProps>
  availableFilterOperations?: string[]
}
const columnProvider: ColumnProviderType[] = [
  {
    types: ['time'],
    formatterComponent: TimeFormatter,
    availableFilterOperations: ['contains'],
  },
  {
    types: ['date'],
    formatterComponent: DateFormatter,
    availableFilterOperations: ['contains'],
  },
  {
    types: ['dateTime'],
    formatterComponent: DateTimeFormatter,
    availableFilterOperations: ['contains'],
  },
  { types: ['color'], formatterComponent: colorFormatter },
  { types: ['medicalOrderStatus'], formatterComponent: StatusFormatter },
  { types: ['sex'], formatterComponent: sexFormatter },
  { types: ['boolean'], formatterComponent: booleanFormatter },
]

type AnyDataTypeProviderType = {
  columns?: string[]
  type: string
}
/**
 * Change the cell for a given column and their filter Icon and predicate
 * @param column
 * @returns `DataTypeProvider`
 */
export const AnyDataTypeProvider = ({
  columns,
  type,
}: AnyDataTypeProviderType) => {
  const col = columnProvider.find(({ types }) => types.includes(type))
  if (col) {
    const { types, formatterComponent, availableFilterOperations } = col
    return (
      <DataTypeProvider
        for={columns ? columns : types}
        formatterComponent={formatterComponent}
        availableFilterOperations={availableFilterOperations}
      />
    )
  } else return null
}
